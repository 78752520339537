import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { DatePicker, Input, Select } from 'antd'
import { Button } from 'lib-react-components'
import { css, cx } from 'emotion'
import { useTheme } from '../../services/theme'
import { IconBack } from '../_shared/Icon'
import { normalizeDir } from './utils'

const ItemSection: React.FC<{
  label: string
  disabled?: boolean
}> = (props) => {
  if (props.disabled) {
    return null
  }

  return (
    <div
      className={css`
        margin-bottom: 10px;
      `}
    >
      <div
        className={css`
          font-size: 14px;
          margin-bottom: 5px;
        `}
      >
        {props.label}
      </div>
      <div>{props.children}</div>
    </div>
  )
}

const PopupHeader: React.FC<{}> = (props) => {
  const theme = useTheme()
  return (
    <div
      className={css`
        border-bottom: 1px solid ${theme.borderColor};
        padding: 0 15px;
        height: 45px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
      `}
    >
      {props.children}
    </div>
  )
}

const PopupContent: React.FC<{}> = (props) => {
  return (
    <div
      className={css`
        padding: 15px 15px;
        margin-bottom: 10px;
      `}
    >
      {props.children}
    </div>
  )
}

export const DraftMetaEditor: React.FC<{
  filename?: string
  value: {
    dir?: string
    tags?: string[]
    date?: Date
  }
  onChange: (value: { dir?: string; tags?: string[]; date?: Date }) => void
  disabled?: boolean
}> = (props) => {
  const { value, onChange, filename, disabled } = props
  const dir = value.dir || ''
  const theme = useTheme()

  // states
  const [dirState, setDirState] = useState(dir)
  const [view, setView] = useState<'main' | 'date'>('main')

  useEffect(() => {
    setDirState(dir)
  }, [dir])

  const renderMain = () => {
    return (
      <div>
        <PopupHeader>
          <div
            className={css`
              font-weight: bold;
            `}
          >
            草稿设置
          </div>
        </PopupHeader>
        <PopupContent>
          <ItemSection label="目录路径" disabled={!filename}>
            <Input
              disabled={disabled}
              value={dirState}
              onBlur={(e) => {
                onChange({
                  dir: e.target.value,
                })
                setDirState(normalizeDir(e.target.value))
              }}
              onChange={(e) => {
                setDirState(e.target.value)
              }}
              placeholder="Path"
            />
            <div
              className={cx(
                'wrap',
                css`
                  margin-top: 3px;
                  margin-left: 2px;
                `,
              )}
            >
              {dirState && (
                <>
                  <strong
                    className={css`
                      font-weight: normal;
                      color: ${theme.textColor};
                    `}
                  >
                    {dirState}
                  </strong>
                  <span
                    className={css`
                      color: ${theme.textColorLight};
                    `}
                  >
                    /
                  </span>
                </>
              )}
              <span
                className={css`
                  color: ${theme.textColorLight};
                `}
              >
                {filename}
              </span>
            </div>
          </ItemSection>
          <ItemSection label="标签">
            <Select
              disabled={disabled}
              value={value.tags}
              mode="tags"
              onChange={(e) => {
                onChange({
                  tags: e,
                })
              }}
              className={css`
                width: 100%;
              `}
              placeholder="Tags"
            >
              {value.tags?.map((tag) => {
                return (
                  <Select.Option key={tag} value={tag}>
                    {tag}
                  </Select.Option>
                )
              })}
            </Select>
          </ItemSection>
          <Button
            linkLike
            size="small"
            onClick={() => {
              setView('date')
            }}
            className={css`
              margin-top: 3px;
            `}
          >
            修改创建日期
          </Button>
        </PopupContent>
      </div>
    )
  }

  const renderDateView = () => {
    return (
      <div>
        <PopupHeader>
          <Button
            linkLike
            size="small"
            onClick={() => {
              setView('main')
            }}
          >
            <IconBack /> 返回
          </Button>
        </PopupHeader>
        <PopupContent>
          <ItemSection label="创建时间">
            <DatePicker
              style={{
                width: '100%',
              }}
              showTime
              allowClear={false}
              value={moment(value.date)}
              onChange={(e) => {
                onChange({
                  date: e.toDate(),
                })
              }}
            />
          </ItemSection>
        </PopupContent>
      </div>
    )
  }

  return (
    <div
      className={css`
        width: 260px;
      `}
    >
      {view === 'date' ? renderDateView() : renderMain()}
    </div>
  )
}

DraftMetaEditor.defaultProps = {}
