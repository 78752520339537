import React from 'react'
import { useTheme } from '../../services/theme'

export const Hint: React.FC<{}> = (props) => {
  const { children } = props
  const theme = useTheme()

  return (
    <div
      style={{
        fontSize: 20,
        color: theme.textColorLight,
        padding: '20px 0',
        textAlign: 'center',
      }}
    >
      {children}
    </div>
  )
}
