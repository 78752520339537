import useSWR, { ConfigInterface, mutate } from 'swr-fork'
import { api } from '../helpers/network'
import { Markdown, RecursivePartial } from '../types/common'
import { Service } from '../types/service'
import { useCallback, useEffect } from 'react'
import { useFocusChange } from 'lib-react-hooks'
import { GitHub } from '../types/github'

// commands ////////////////////////////////////////////////////////////////////////////////////////

export const useFetchRemote = (config: {
  onChange?: () => void
  branch: string
  owner: string
  repo: string
}) => {
  const { owner, repo, branch, onChange } = config

  const doFetch = useCallback(() => {
    if (branch) {
      fetch(owner, repo, branch).then((change) => {
        const all = Object.values(change).flat()
        if (all.length) {
          onChange?.()
        }
      })
    }
  }, [owner, repo, branch, onChange])

  useFocusChange(doFetch)

  useEffect(() => {
    doFetch()
  }, [doFetch])
}

export const pull = (owner: string, repo: string, branch: string) => {
  return api.put(`git/repos/${owner}/${repo}/pull/${branch}`)
}

export const checkout = (params: {
  owner: string
  repo: string
  branch?: string
  paths?: string[]
}) => {
  const { owner, repo, branch, paths } = params
  if (branch) {
    return api.put<unknown>(`git/repos/${owner}/${repo}/checkout/${branch}`)
  }
  return api.put<unknown>(`git/repos/${owner}/${repo}/checkout`, {
    paths,
  })
}

type RefRecord = {
  sha: string
  name: string
}

export const fetch = (owner: string, repo: string, branch: string) => {
  return api.put<{
    added: RefRecord[]
    updated: RefRecord[]
    removed: RefRecord[]
  }>(`git/repos/${owner}/${repo}/fetch/${branch}`)
}

export type ReferenceRecord = {
  type: 'add' | 'remove'
  sha: string
  path: string
}

export const commit = (data: {
  owner: string
  repo: string
  parentCommit: string
  message: string
  branch: string
  records: ReferenceRecord[]
}) => {
  const { owner, repo, ...rest } = data
  return api.post<GitHub.Commit>(`git/repos/${owner}/${repo}/commits`, rest)
}

export const useGitStatus = (owner: string, repo: string) => {
  return useSWR<Service.StatusSummary>(`git/repos/${owner}/${repo}/status`, {
    suspense: true,
  })
}

export const invalidateGitStatus = (owner: string, repo: string) => {
  return mutate(`git/repos/${owner}/${repo}/status`, true)
}

export const useGitHead = (owner: string, repo: string) => {
  return useSWR<Service.Head>(`git/repos/${owner}/${repo}/head`, {
    suspense: true,
  })
}

export const invalidateGitHead = (owner: string, repo: string) => {
  return mutate(`git/repos/${owner}/${repo}/head`, true)
}

/**
 * @deprecated
 */
export const invalidateGitLocal = (owner: string, repo: string) => {
  // TODO more
  return Promise.all([invalidateGitStatus(owner, repo), invalidateGitHead(owner, repo)])
}

export const initWorkingTree = (owner: string, repo: string) => {}

// repo ////////////////////////////////////////////////////////////////////////////////////////////

export const useRepos = () => {
  return useSWR<GitHub.Repo[]>(`git/repos`, {
    suspense: true,
  })
}

export const useRepo = (owner: string, repo: string, swrConfig: ConfigInterface = {}) => {
  return useSWR<GitHub.Repo>(owner && repo && `git/repos/${owner}/${repo}`, {
    suspense: true,
    revalidateOnFocus: false,
    ...swrConfig,
  })
}

export const fetchRepo = (owner: string, repo: string) => {
  return api.get<GitHub.Repo>(`git/repos/${owner}/${repo}`)
}

export const cloneRepo = (owner: string, repo: string) => {
  return api.put(`git/repos/${owner}/${repo}/clone`)
}

export const syncRepoInfo = (owner: string, repo: string) => {
  return api.put(`git/repos/${owner}/${repo}`)
}

export const deleteRepo = (id: string) => {
  return api.delete(`git/repos/${id}`)
}

// branches ////////////////////////////////////////////////////////////////////////////////////////

export const useBranch = (
  owner: string,
  repo: string,
  branch?: string,
  swrConfig: ConfigInterface = {},
) => {
  return useSWR<GitHub.Repo>(
    owner && repo && branch && `git/repos/${owner}/${repo}/branches/${branch}`,
    {
      suspense: true,
      revalidateOnFocus: false,
      ...swrConfig,
    },
  )
}

export const useBranches = (owner: string, repo: string) => {
  return useSWR<Service.Ref[]>(`git/repos/${owner}/${repo}/branches`, {
    suspense: true,
  })
}

// blobs ///////////////////////////////////////////////////////////////////////////////////////////

export const useBlob = (config: {
  owner: string
  repo: string
  blobRef?: string
  disabled?: boolean
  fallback?: unknown
  suspense?: boolean
}) => {
  const { owner, repo, blobRef, disabled, fallback, suspense = true } = config
  const url = `git/repos/${owner}/${repo}/blobs/${blobRef}`

  return useSWR<Service.Blob>(
    !disabled && url,
    (url) =>
      api.get(url).catch((err) => {
        if (fallback !== undefined) {
          return fallback
        }
        throw err
      }),
    {
      suspense,
      revalidateOnFocus: false,
    },
  )
}

export const getGitBlob = (owner: string, repo: string, blobRef: string) => {
  return api.get<Service.Blob>(`git/repos/${owner}/${repo}/blobs/${blobRef}`)
}

// commits /////////////////////////////////////////////////////////////////////////////////////////

export const getCommit = (owner: string, repo: string, commitSha: string) => {
  return api.get(`git/repos/${owner}/${repo}/commits/${commitSha}`)
}

export const useCommit = (owner: string, repo: string, commitSha) => {
  return useSWR<Service.Head>(`git/repos/${owner}/${repo}/commits/${commitSha}`, {
    suspense: true,
  })
}

// virtual files ///////////////////////////////////////////////////////////////////////////////////

export const getVirtualFile = (config: { owner: string; repo: string; path: string }) => {
  const { owner, repo, path } = config
  return api.get<Service.Blob>(`git/repos/${owner}/${repo}/virtual-files/${path}`)
}

export const createVirtualFile = (config: {
  owner: string
  repo: string
  path: string
  markdown: Markdown
}) => {
  const { owner, repo, path, markdown } = config
  return api.post<Service.Blob>(`git/repos/${owner}/${repo}/virtual-files`, {
    path,
    _markdown: markdown,
  })
}

export const updateVirtualFile = (config: {
  owner: string
  repo: string
  path: string
  content?: string // base64
  _markdown?: RecursivePartial<Markdown>
  _json?: any
  _text?: string
  sha: string
}) => {
  const { owner, repo, path, ...rest } = config

  return api.put<Service.Blob>(`git/repos/${owner}/${repo}/virtual-files/${path}`, rest)
}

export const deleteVirtualFile = (_id: string) => {
  return api.delete(`git/repos/virtual-files/${_id}`)
}

export const useVirtualFile = (config: {
  owner: string
  repo: string
  path: string
  disabled?: boolean
  fallback?: unknown
  suspense?: boolean
}) => {
  const { owner, repo, path, disabled, fallback, suspense = true } = config
  const url = `git/repos/${owner}/${repo}/virtual-files/${path}`

  return useSWR<Service.Blob>(
    !disabled && url,
    (url) =>
      api.get(url).catch((err) => {
        if (fallback !== undefined) {
          return fallback
        }
        throw err
      }),
    {
      suspense,
    },
  )
}
