import { useObservable } from 'lib-react-hooks'
import { getPreference, preference$, updatePreference } from './service'

export const usePreference = () => {
  const { value } = useObservable(preference$, () => getPreference())

  return {
    preference: value,
    update: updatePreference,
  }
}
