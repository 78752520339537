import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Input, message } from 'antd'
import { Button } from 'lib-react-components'
import { commit } from '../../../services/github'
import { debounce } from 'lodash'
import { GitHub } from '../../../types/github'
import { css } from 'emotion'
import { useAutoUpdateRef } from 'lib-react-hooks'

export const CommitEditorLegacy: React.FC<{
  owner: string
  repo: string
  content: string
  path: string
  prevBlob?: GitHub.RawContentFile
  onCommitSuccess?: () => void
  branch: string
  disabled?: boolean
}> = (props) => {
  const { owner, repo, content, path, prevBlob, onCommitSuccess, branch, disabled } = props
  const [isCommitting, setIsCommitting] = useState(false)
  const [commitMsg, setCommitMsg] = useState('')
  const prevContent = prevBlob?.content
  const prevSha = prevBlob?.sha

  useEffect(() => {
    if (prevContent) {
      setCommitMsg(`Update file ${path}`)
    } else {
      setCommitMsg(`Create file ${path}`)
    }
  }, [prevContent, path])

  const handleCommit = useCallback(() => {
    if (!path) {
      message.error(`Path is required`)
      return
    }

    // TODO 更通用的处理
    if (!path.endsWith('.md')) {
      message.error(`File has to end with ".md"`)
      return
    }

    setIsCommitting(true)

    commit(
      {
        owner,
        repo,
        path,
      },
      {
        message: commitMsg,
        branch,
        content,
        sha: prevSha,
      },
    )
      .then(() => {
        message.success(`Commit success`)
        onCommitSuccess?.()
      })
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => {
        setIsCommitting(false)
      })
  }, [branch, commitMsg, content, onCommitSuccess, owner, path, prevSha, repo])

  const handleCommitRef = useAutoUpdateRef(handleCommit)

  const handleCommitDebounced = useMemo(() => {
    return debounce(() => handleCommitRef.current(), 100)
  }, [handleCommitRef])

  return (
    <div>
      <Input
        disabled={disabled}
        value={commitMsg}
        onChange={(e) => {
          setCommitMsg(e.target.value)
        }}
        className={css`
          margin-bottom: 10px;
          width: 100%;
        `}
        placeholder="Message"
      />
      <Button isLoading={isCommitting} onClick={handleCommitDebounced} disabled={disabled}>
        Commit
      </Button>
    </div>
  )
}
