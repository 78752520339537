import React, { Suspense, useEffect } from 'react'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { NavBar, Loading } from 'lib-react-components'
import { useScrollEnd, useSetDocumentTitle } from 'lib-react-hooks'
import { SearchButton, SiteHeaderWithSearch } from '../_core/SiteHeader'
import { MainContent } from '../_shared/MainContent'
import { Link, useHistory } from 'react-router-dom'
import { List } from '../_shared/List'
import { deleteDraft, useDraftPages } from '../../services/docs'
import { useURLHelper } from '../../hooks/useURLHelper'
import { useQueryState } from '../../hooks/useQueryState'
import { Post } from '../_shared/Post'
import { RouterSearch } from '../_shared/RouterSearch'
import { WorkingTreeStatus } from './WorkingTreeStatus'
import { useGitHead, useGitStatus } from '../../services/git'
import { RouterSortSelect } from '../_core/RouterSortSelect'
import { DEFAULT_SORT } from '../../config'
import { RouterFilter } from '../_core/RouterFilter'
import { Hint } from '../_shared/Hint'
import { useSetLastUsed } from '../../hooks/useSetLastUsed'
import { BasicDropdown } from '../_shared/BasicDropdown'
import { atom, useRecoilState, useRecoilValue } from 'recoil'
import { Service } from '../../types/service'
import { useSubscribeAppEvents } from '../../services/subscription'
import { Menu } from 'antd'
import { useIsMobile } from '../../hooks/useIsMobile'
import { css } from 'emotion'
import { CreateDraftButton } from '../_core/CreateDraftButton'

const headState = atom<Service.Head>({
  key: 'draft-list-head',
  default: null,
})

const DraftListContent: React.FC<{}> = (props) => {
  const { owner, repo } = useRepoParams()
  const { data: gitStatus } = useGitStatus(owner, repo)
  const { data: head } = useGitHead(owner, repo)
  const url = useURLHelper(head.current)
  const [query] = useQueryState<{
    search: string
    sort: string
    tag: string
  }>()
  const sort = query.sort || DEFAULT_SORT
  const swr = useDraftPages({
    owner,
    repo,
    sort,
    search: query.search,
    tag: query.tag,
  })
  const { loadMore, revalidate: revalidateDrafts } = swr
  const [, setHead] = useRecoilState(headState)
  const history = useHistory()

  useScrollEnd(loadMore, {
    margin: 10,
    debounceWait: 100,
  })

  useEffect(() => {
    setHead(head)
    return () => {
      setHead(null)
    }
  }, [head, setHead])

  useSetDocumentTitle(`${owner}/${repo}`)

  useSubscribeAppEvents((e) => {
    if (e.key === 'branchChange' || e.key === 'pull') {
      revalidateDrafts()
    }
  })

  return (
    <>
      <RouterFilter />
      {gitStatus.workingTreeReady ? (
        <List
          enhancedSWR={swr}
          itemComponent={Post}
          preprocessItemProps={(data) => {
            return {
              data,
              url: url.getDraftUrl(data.path),
              dateType: (sort.startsWith('updated') ? 'updated' : 'date') as any,
              onDelete: async () => {
                await deleteDraft({
                  owner,
                  repo,
                  slug: data._id,
                })
                revalidateDrafts()
              },
              onEdit: () => {
                history.push(url.getEditorUrl(data._id))
              },
              getTagUrl: (tag) => {
                return url.getDraftUrl() + `?tag=${tag}`
              },
              githubUrl: url.getGitHubBlobUrl(data.path),
              status: gitStatus.fileMap?.[data.path]?.type,
            }
          }}
        />
      ) : (
        <Hint>工作区未就绪</Hint>
      )}
    </>
  )
}

export const DraftListPage: React.FC<{}> = () => {
  const { owner, repo, ref = '' } = useRepoParams()
  const head = useRecoilValue(headState)
  const url = useURLHelper(head?.current)
  const mobileFlag = useIsMobile()

  useSetLastUsed()

  return (
    <>
      <SiteHeaderWithSearch
        left={
          <>
            {!mobileFlag && (
              <RouterSearch
                className={css`
                  width: 100%;
                `}
              />
            )}
          </>
        }
        right={
          <NavBar
            items={[
              <CreateDraftButton />,
              mobileFlag && <SearchButton />,
              !mobileFlag && <RouterSortSelect />,
              <BasicDropdown
                items={[
                  {
                    content: <Link to={url.getRemoteIndex()}>远程</Link>,
                  },
                  {
                    content: <Link to={url.getLogsUrl()}>日志</Link>,
                  },
                  <Menu.Divider />,
                  {
                    content: owner && repo && (
                      <Link to={`/archive/${owner}/${repo}/${ref}`}>归档</Link>
                    ),
                  },
                ]}
              />,
            ]}
          />
        }
      />
      <MainContent
        statusBar={
          <Suspense fallback={<Loading size="small" text="初始化 git status..." />}>
            <WorkingTreeStatus />
          </Suspense>
        }
      >
        <DraftListContent />
      </MainContent>
    </>
  )
}

export default DraftListPage
