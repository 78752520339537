import { createKvStorage } from 'lib-core'
import {
  createUseThemeHook,
  ComponentTheme,
  getComponentTheme,
  BaseThemeConfig,
  getThemeConfig,
  themeConfig$,
} from 'lib-react-components'
import { Observable, combineLatest } from 'rxjs'
import { map, share, skip, startWith } from 'rxjs/operators'
import { CONTENT_WIDTH, BAR_HEIGHT, STATUS_BAR_HEIGHT } from '../config'
import { getPreference, preference$ } from '../components/preference/service'
import { filterChanged } from '../utils'

// external css ////////////////////////////////////////////////////////////////////////////////////
const unmountNode = (node: Element) => {
  node.parentNode?.removeChild(node)
}

// url -> source
const assetsCache = createKvStorage<Record<string, string>>('gitdocs_assets')
const styleLoadCache = new Map<
  string /* url */,
  {
    promise: Promise<string>
    value?: string
  }
>()

export const loadThemeAssetsCache = () => {
  const keys = assetsCache.keys

  keys.map(async (key) => {
    const source = assetsCache.get(key)
    if (source) {
      styleLoadCache.set(key, {
        promise: Promise.resolve(source),
        value: source,
      })
    }
  })
}

const fetchStyleSource = (url: string) => {
  const cache = styleLoadCache.get(url)

  if (cache) {
    return cache
  }

  const loadPromise = fetch(url).then(async (res) => {
    const source = await res.text()
    assetsCache.set(url, source)
    return source
  })
  styleLoadCache.set(url, {
    promise: loadPromise,
  })

  return {
    promise: loadPromise,
  }
}

export const mountStyle = async (url: string) => {
  let { promise, value: source } = fetchStyleSource(url)
  if (!source) {
    source = await promise
  }

  const styleEle = document.createElement('style')
  styleEle.innerHTML = source
  document.head.prepend(styleEle)

  return () => {
    unmountNode(styleEle)
  }
}

export const mountLink = async (url: string) => {
  const doMount = (link: HTMLLinkElement) => {
    // TODO 实现前端性能埋点
    console.time(`load style ${url}`)
    document.head.prepend(link)

    return new Promise((resolve) => {
      link.addEventListener('load', () => {
        resolve(null)
        console.timeEnd(`load style ${url}`)
      })
    })
  }

  const link = document.createElement('link')
  link.setAttribute('href', url)
  link.setAttribute('rel', 'stylesheet')
  await doMount(link)

  return () => {
    unmountNode(link)
  }
}

// theme ///////////////////////////////////////////////////////////////////////////////////////////
type ExtendTheme = {
  ideAsideBg: string
  textColorLight: string
  statusBarHeight: number
}

export type Theme = ComponentTheme & ExtendTheme
export type ThemeConfig = BaseThemeConfig<{}>

export const getEnhancedTheme = (config: ThemeConfig) => {
  const base = getComponentTheme(config)

  return {
    ...base,
    ...(config.dark
      ? { ideAsideBg: '#333', textColorLight: '#555' }
      : {
          ideAsideBg: 'rgb(240, 240, 240)',
          textColorLight: '#999',
        }),
    contentWidth: CONTENT_WIDTH,
    appBarHeight: BAR_HEIGHT,
    statusBarHeight: STATUS_BAR_HEIGHT,
  }
}

export const useTheme = createUseThemeHook<Theme>()

export const getEnhancedThemeConfig = (): ThemeConfig => {
  return {
    ...getThemeConfig(),
    dark: getPreference().colorScheme === 'dark',
  }
}

export const enhancedThemeConfig$ = combineLatest([
  preference$.pipe(
    map((p) => p.colorScheme),
    startWith(getPreference().colorScheme),
  ),
  themeConfig$.pipe(startWith(getThemeConfig())),
]).pipe(
  skip(1),
  map(([userPrefersDark, config]) => {
    return {
      ...config,
      dark: userPrefersDark ? userPrefersDark === 'dark' : config.dark,
    }
  }),
  share(),
)

export const dark$: Observable<boolean> = enhancedThemeConfig$.pipe(
  map((config) => config.dark),
  filterChanged(getEnhancedThemeConfig().dark),
  share(),
)

// theme side effect
let LAST_UNMOUNT: () => void

export const applyExternalCss = async (dark: boolean) => {
  const styleUrl = dark ? '/antd.dark.min.css' : '/antd.min.css'

  await mountStyle(styleUrl).then((unmount) => {
    LAST_UNMOUNT?.()
    LAST_UNMOUNT = unmount
  })
}

dark$.subscribe((dark) => {
  applyExternalCss(dark)
})
