import qs from 'qs'

export const PREFIX_REMOTE = 'remote'
export const PREFIX_DRAFTS = 'drafts'

export const encodeURI = (uri: string) => {
  return uri.split('#').join('%23')
}

export const decodeURI = (uri: string) => {
  return uri.split('%23').join('#')
}

export const extractQuery = (urlLike: string) => {
  const i = urlLike.indexOf('?')
  const qsStr = urlLike.substr(i + 1)
  return qs.parse(qsStr)
}

export const getLogsUrl = (owner: string, repo: string) => {
  return encodeURI(`/${owner}/${repo}/logs`)
}

export const getGitHubBlobUrl = (owner: string, repo: string, ref: string, path: string) => {
  return encodeURI(`https://github.com/${owner}/${repo}/blob/${ref}/${path}`)
}

// remote //////////////////////////////////////////////////////////////////////////////////////////

export const getRepoUrl = (
  owner: string,
  repo: string,
  ref?: string,
  query?: Record<string, string>,
) => {
  const q = query ? `?${qs.stringify(query)}` : ''
  if (ref) {
    return `/${PREFIX_REMOTE}/${owner}/${repo}/tree/${ref}${q}`
  }
  return `/${PREFIX_REMOTE}/${owner}/${repo}${q}`
}

export const getBlobUrl = (owner: string, repo: string, ref: string, path: string) => {
  return encodeURI(`/${PREFIX_REMOTE}/${owner}/${repo}/blob/${ref}/${path}`)
}

// drafts //////////////////////////////////////////////////////////////////////////////////////////

export const getDraftUrl = (owner: string, repo: string, slug?: string) => {
  if (!slug) {
    return encodeURI(`/${PREFIX_DRAFTS}/${owner}/${repo}`)
  }
  return encodeURI(`/${PREFIX_DRAFTS}/${owner}/${repo}/${slug}`)
}

export const getEditorUrl = (owner: string, repo: string, slug: string) => {
  return encodeURI(`/${PREFIX_DRAFTS}/edit/${owner}/${repo}/${slug}`)
}

export const getCommitEditorUrl = (owner: string, repo: string) => {
  return encodeURI(`/commit/${owner}/${repo}`)
}

// legacy //////////////////////////////////////////////////////////////////////////////////////////
/**
 * @deprecated
 */
export const getDraftUrlLegacy = (owner: string, repo: string, draftId: string) => {
  return `/ide/${owner}/${repo}/?draftId=${draftId}`
}

/**
 * @deprecated
 */
export const getDraftsIndexUrlLegacy = (owner: string, repo: string) => {
  return `/ide/${owner}/${repo}`
}

/**
 * @deprecated
 */
export const getForkUrl = (owner: string, repo: string, blobSha: string) => {
  return encodeURI(`/fork/${owner}/${repo}/${blobSha}`)
}
