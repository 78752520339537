import React, { HTMLAttributes, useEffect } from 'react'
import { SearchInput } from 'lib-react-components'
import { useState } from 'react'
import { useCallback } from 'react'
import { useQueryState } from '../../hooks/useQueryState'
import { useURLHelper } from '../../hooks/useURLHelper'
import { useAutoUpdateRef, useDebounce } from 'lib-react-hooks'

export const RouterSearch: React.FC<{
  // 和 react 的 ref 重名了
  _ref?: string
} & HTMLAttributes<HTMLDivElement>> = (props) => {
  const { _ref, ...rest } = props
  const [{ search = '' }, setQuery] = useQueryState<{
    search: string
  }>()
  const url = useURLHelper(_ref)
  const [keywords, setKeywords] = useState(search)
  const setQueryDe = useDebounce(setQuery, 200)
  const keywordsRef = useAutoUpdateRef(keywords)

  const updateSearch = useCallback(
    (nextQ: string) => {
      const replace = Boolean(search && nextQ)

      setQueryDe(
        (query) => {
          return {
            ...query,
            search: nextQ,
          }
        },
        {
          replace: replace,
          pathname: url.getIndexUrl(),
        },
      )
      setKeywords(nextQ)
    },
    [search, setQueryDe, url],
  )

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateSearch(e.target.value)
    },
    [updateSearch],
  )

  const handleClear = useCallback(() => {
    updateSearch('')
  }, [updateSearch])

  useEffect(() => {
    if (keywordsRef.current !== search) {
      setKeywords(search)
    }
  }, [search, keywordsRef])

  return (
    <SearchInput
      {...rest}
      value={keywords}
      onChange={handleSearch}
      onClear={handleClear}
      placeholder="搜索..."
    />
  )
}
