import moment from 'moment'
import { Observable } from 'rxjs'
import { filter, map, pairwise, startWith } from 'rxjs/operators'
import { formatDistanceToNow, differenceInDays } from 'date-fns'
import localeZhCn from 'date-fns/locale/zh-CN'

export const getRelativeTime = (date: any) => {
  const distanceDays = differenceInDays(new Date(), date)

  if (distanceDays > 35) {
    return formatDate(date)
  }

  return formatDistanceToNow(date, {
    addSuffix: true,
    locale: localeZhCn,
  }).replace('大约 ', '')
}

export const formatDate = (date: any, onlyDate = false) => {
  if (onlyDate) {
    return moment(date).format('YYYY/MM/DD')
  }
  return moment(date).format('YYYY/MM/DD HH:mm:ss')
}

export const subDateLike = (date1: any, date2: any) => {
  return new Date(date1).valueOf() - new Date(date2).valueOf()
}

export const isSha = (sha: string) => /^[a-f0-9]{40}$/.test(sha)

// https://stackoverflow.com/a/30106551/1908126
export function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
}

export const ensureArray = <T>(input: T) => (!input ? [] : Array.isArray(input) ? input : [input])

export const shortenSha = (sha: string) => {
  return sha.slice(0, 6)
}

export const findItemIndex = <T>(arr: T[], fn: (item: T) => boolean) => {
  for (let i = 0; i < arr.length; i++) {
    if (fn(arr[i])) {
      return i
    }
  }
  return -1
}

// https://stackoverflow.com/questions/10527983/best-way-to-detect-mac-os-x-or-windows-computers-with-javascript-or-jquery
export const checkIsMac = () => {
  return navigator.platform.toUpperCase().indexOf('MAC') >= 0
}

export const filterChanged = <T>(
  initial: T = null,
  isChanged: (prev: T, curr: T) => boolean = (a, b) => a !== b,
) => {
  return (observable: Observable<T>) => {
    return observable.pipe(
      startWith(initial),
      pairwise(),
      map(([prev, curr]) => {
        return {
          changed: isChanged(prev as T, curr as T),
          curr: curr as T,
        }
      }),
      filter((v) => v.changed),
      map((v) => v.curr),
    )
  }
}

export const toMap = <T extends {}, K extends keyof T>(arr: T[], key: K) => {
  return arr.reduce((map, item) => {
    return {
      ...map,
      [(item[key] as unknown) as string]: item,
    }
  }, {} as Record<string | number, T>)
}
