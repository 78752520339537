import React from 'react'
import { useRecentRepos } from '../../services/docs'
import { Link } from 'react-router-dom'
import { MainContent } from '../_shared/MainContent'
import { SiteHeader } from '../_core/SiteHeader'
import { useSetDocumentTitle } from 'lib-react-hooks'
import { useTheme } from '../../services/theme'

export const RecentRepos: React.FC<{}> = (props) => {
  const theme = useTheme()
  const { data } = useRecentRepos()

  useSetDocumentTitle('Recent repos')

  return (
    <>
      <SiteHeader />
      <MainContent>
        <h1
          className="page-title"
          style={{
            color: theme.textColor,
          }}
        >
          Recent
        </h1>
        {data.length === 0 ? (
          <div>Nothing here...</div>
        ) : (
          data.map((item) => {
            return (
              <h2
                key={item._id}
                style={{
                  margin: '0 0 3px',
                  fontSize: 22,
                }}
              >
                <Link to={`/github/${item.owner.login}/${item.name}`}>
                  {item.name}/{item.owner.login}
                </Link>
              </h2>
            )
          })
        )}
        <Link to="/repos">Browse more...</Link>
      </MainContent>
    </>
  )
}

export default RecentRepos
