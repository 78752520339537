import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { LoadingScreen } from 'lib-react-components'
import { SiteHeader } from '../_core/SiteHeader'
import { MainContent } from '../_shared/MainContent'
import { useLastDraftUrl } from '../../hooks/useLastDraftUrl'

export const Home: React.FC<{}> = () => {
  const history = useHistory()
  const url = useLastDraftUrl()

  useEffect(() => {
    history.push(url)
  }, [history, url])

  return (
    <>
      <SiteHeader />
      <MainContent requireAuth={false}>
        <LoadingScreen text="跳转中..." />
      </MainContent>
    </>
  )
}

export default Home
