import React from 'react'

export const NoResult: React.FC<{
  title?: string
}> = (props) => {
  return (
    <div
      style={{
        margin: '60px auto',
        textAlign: 'center',
      }}
    >
      <h1
        style={{
          margin: 0,
          fontSize: 20,
          color: '#999',
        }}
      >
        {props.title}
      </h1>
      {props.children}
    </div>
  )
}

NoResult.defaultProps = {
  title: '暂无数据',
}
