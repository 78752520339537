import _ from 'lodash'
import { History } from 'history'
import { RepoQuery } from './types'
import qs from 'qs'
import { GitHub } from '../../types/github'

export const repoTypes = ['public', 'private', 'sources', 'forks', 'archived', 'template']

type Repo = GitHub.Repo

export function extractTopicsFromRepos(repos: Repo[]) {
  const topics: {
    [key: string]: {
      count: number
    }
  } = {}

  repos.forEach((repo) => {
    if (repo.topics.length === 0) {
      if (!topics.__noTopic) {
        topics.__noTopic = { count: 0 }
      }
      topics.__noTopic.count++
      return
    }

    repo.topics.forEach((t) => {
      if (topics[t]) {
        topics[t].count++
      } else {
        topics[t] = {
          count: 1,
        }
      }
    })
  })

  return _.map(topics, (v, k) => {
    const isNoTopic = k === '__noTopic'
    const name = isNoTopic ? 'No topic' : k
    const value = isNoTopic ? 'null' : k

    return {
      ...v,
      name,
      value,
      isNoTopic,
    }
  }).sort((a, b) => {
    if (a.isNoTopic) {
      return -1
    }
    if (b.isNoTopic) {
      return 1
    }
    return b.count - a.count
  })
}

export function extractLanguagesFromRepos(repos: Repo[]) {
  const langs: {
    [key: string]: {
      count: number
    }
  } = {}

  repos.forEach((repo) => {
    const t = repo.language
    if (langs[t]) {
      langs[t].count++
    } else {
      langs[t] = {
        count: 1,
      }
    }
  })
  return _.map(langs, (v, k) => {
    const name = k === 'null' ? 'Other' : k
    return {
      ...v,
      name,
    }
  }).sort((a, b) => b.count - a.count)
}

export const typeToRepoAttrMap: {
  [key: string]: (r: Repo) => boolean
} = {
  public: (r) => !r.private,
  private: (r) => r.private,
  sources: (r) => !r.fork,
  forks: (r) => r.fork,
  archived: (r) => r.archived,
  template: (r) => r.is_template,
}

export const processReposQuery = (repos: Repo[], query: RepoQuery) => {
  const filteredRepos = repos.filter((repo) => {
    if (query.keywords && !repo.name.includes(query.keywords)) {
      return false
    }

    if (query.type && !typeToRepoAttrMap?.[query.type]?.(repo)) {
      return false
    }

    if (
      query.topic &&
      (query.topic === 'null' ? repo.topics.length !== 0 : !repo.topics.includes(query.topic))
    ) {
      return false
    }

    if (query.lang && (query.lang === 'Other' ? repo.language : repo.language !== query.lang)) {
      return false
    }

    return true
  })

  if (query.sort) {
    return sortRepos(filteredRepos, query.sort)
  }

  return filteredRepos
}

const subDateLike = (date1: any, date2: any) => {
  return new Date(date1).valueOf() - new Date(date2).valueOf()
}

export const sortRepos = (notes: Repo[], sort: string = 'created_at-desc') => {
  const [by, _direction] = sort.split('-')
  const direction = _direction === 'desc' ? -1 : 1

  return [...notes].sort((a, b) => {
    const propA = a[by]
    const propB = b[by]

    switch (by) {
      case 'created_at':
      case 'updated_at': {
        return subDateLike(propA, propB) * direction
      }

      case 'name': {
        return propA.localeCompare(propB, 'zh-cn') * direction
      }

      case 'stargazers_count': {
        return (propA - propB) * direction
      }

      default:
        console.warn(`sort ${by} is not supported`)
        break
    }
  })
}

export const updateUrlWithQuery = (history: History, query: Partial<RepoQuery>) => {
  const currentQuery: RepoQuery = qs.parse(history.location.search.substr(1))
  const to = `${history.location.pathname}?${qs.stringify({
    ...currentQuery,
    ...query,
  })}`
  return to
}
