import { clearClientData } from 'lib-core'
import useSWR, { ConfigInterface } from 'swr-fork'
import { api } from '../helpers/network'
import { Service } from '../types/service'

export const fetchProfile = () => api.get('/auth/profile')

export const signOut = () =>
  api.post('/auth/tokens/revoke').then(() => {
    // TODO idb 没有在这个里面
    clearClientData()
    window.location.href = '/'
  })

export const retrieveGithubOAuthResult = (data) => {
  return api.post('/auth/github/access-token', {
    code: data.code,
    state: data.state,
  })
}

export const useUser = (swrConfig: ConfigInterface = {}) => {
  return useSWR<Service.User>('/auth/profile', {
    suspense: true,
    ...swrConfig,
  })
}
