import { message } from 'antd'

export const handleServiceError = (type: 'message' | 'alert' = 'message', throwError = false) => {
  return (resp: { message: string; code: number }) => {
    const { message: msg, code } = resp
    if (type === 'alert') {
      alert(`TODO: ${msg}`)
    } else {
      message.error(`${code ? `${code}: ` : ''}${msg}`)
    }
    if (throwError) {
      throw resp
    }
  }
}

export const isAuthError = (err: any) => {
  return typeof err === 'object' && err.name === 'UnauthorizedError'
}
