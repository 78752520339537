import { useCallback, useEffect, useRef } from 'react'

export type BaseEvent = {
  key: string
  value: unknown
}

type Listener<T extends BaseEvent> = (e: T) => void

export type SubscriptionContext<T extends BaseEvent> = {
  listeners: Listener<T>[]
  notify: (e: T) => void
  subscribe: (listener: Listener<T>) => () => void
  // TODO on, once
}

export const createSubscriptionContext = <T extends BaseEvent>(): SubscriptionContext<T> => {
  const listeners: ((e: T) => void)[] = []

  const notify = (e: T) => {
    listeners.forEach((listener) => {
      listener(e)
    })
  }

  const subscribe = (listener: Listener<T>) => {
    listeners.push(listener)

    return () => {
      const idx = listeners.indexOf(listener)
      listeners.splice(idx, 1)
    }
  }

  return {
    listeners,
    notify,
    subscribe,
  }
}

export const createSubscriptionHooks = <T extends BaseEvent>(context: SubscriptionContext<T>) => {
  const useSubscribe = (listener: Listener<T>) => {
    const listenerRef = useRef(listener)

    useEffect(() => {
      const _listener = (e: T) => {
        listenerRef.current(e)
      }

      return context.subscribe(_listener)
    }, [])
  }

  const useNotify = () => {
    return useCallback((e: T) => {
      context.notify(e)
    }, [])
  }

  return {
    useSubscribe,
    useNotify,
  }
}
