import path from 'path'

export const parsePath = (pathStr: string) => {
  if (!pathStr) {
    return {
      dir: '',
      filename: '',
    }
  }

  const i = pathStr.lastIndexOf('/')
  const dir = pathStr.substring(0, i)
  const filename = pathStr.substring(i + 1)
  return {
    dir,
    filename,
  }
}

export const normalizeDir = (dir: string) => {
  if (!dir) {
    return ''
  }
  return path.normalize(dir).replace(/(^[/]+|[/]+$)/g, '')
}

export const toPath = (dir: string, filename: string) => {
  return [normalizeDir(dir), toPathSegment(filename)].filter(Boolean).join('/')
}

/**
 * 格式化路径中的某一段，确保不产生新的路径节点 /path/{segment}/
 */
export const toPathSegment = (segment: string) => {
  return path.normalize(segment).replaceAll('/', '*')
}
