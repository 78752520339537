import {
  getBlobUrl,
  getDraftUrl,
  getRepoUrl,
  getCommitEditorUrl,
  getEditorUrl,
  getLogsUrl,
  getGitHubBlobUrl,
  PREFIX_REMOTE,
} from '../helpers/urls'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useRepoParams } from './useRepoQuery'

export const useURLHelper = (inputRef?: string) => {
  const { owner, repo, ref: paramRef } = useRepoParams()
  const ref = inputRef || paramRef
  const history = useHistory()
  const isRemote = history.location.pathname.startsWith(`/${PREFIX_REMOTE}/`)

  const _getBlobUrl = useCallback(
    (path: string) => {
      return getBlobUrl(owner, repo, ref, path)
    },
    [owner, ref, repo],
  )

  const _getDraftUrl = useCallback(
    (slug?: string) => {
      return getDraftUrl(owner, repo, slug)
    },
    [owner, repo],
  )

  const getRemoteIndex = useCallback(() => {
    return getRepoUrl(owner, repo, ref)
  }, [owner, ref, repo])

  const _getEditorUrl = useCallback(
    (slug: string) => {
      return getEditorUrl(owner, repo, slug)
    },
    [owner, repo],
  )

  const _getCommitEditorUrl = useCallback(() => {
    return getCommitEditorUrl(owner, repo)
  }, [owner, repo])

  const _getLogsUrl = useCallback(() => {
    return getLogsUrl(owner, repo)
  }, [owner, repo])

  const getIndexUrl = useCallback(() => {
    return isRemote ? getRemoteIndex() : _getDraftUrl()
  }, [_getDraftUrl, getRemoteIndex, isRemote])

  const _getGitHubBlobUrl = useCallback(
    (path: string) => {
      return getGitHubBlobUrl(owner, repo, ref, path)
    },
    [owner, ref, repo],
  )

  return {
    isRemote,
    getIndexUrl,
    getRemoteIndex,
    getGitHubBlobUrl: _getGitHubBlobUrl,
    getBlobUrl: _getBlobUrl,
    getEditorUrl: _getEditorUrl,
    getCommitEditorUrl: _getCommitEditorUrl,
    getDraftUrl: _getDraftUrl,
    getLogsUrl: _getLogsUrl,
  }
}
