import React from 'react'
import { Logo as Logo0 } from 'lib-react-components'
import logoUrl from '../../assets/logo.png'
import { useLastDraftUrl } from '../../hooks/useLastDraftUrl'

export const Logo: React.FC<{}> = () => {
  const url = useLastDraftUrl()

  return <Logo0 to={url} imageUrl={logoUrl} />
}
