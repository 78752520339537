import { Select } from 'lib-react-components'
import React from 'react'
import { SORT_OPTIONS } from '../../config'

export const SortSelect: React.FC<{
  value: string
  onChange: (e: string) => void
}> = (props) => {
  const { value, onChange } = props

  return (
    <Select placeholder="选择排序..." value={value} onChange={onChange} options={SORT_OPTIONS} />
  )
}
