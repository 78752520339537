import React from 'react'
import { useGithubRepoPages } from '../../services/github'
import { Button } from 'lib-react-components'
import classNames from 'classnames'
import { css } from 'emotion'
import { useTheme } from '../../services/theme'
import { GitHub } from '../../types/github'
import { List } from '../_shared/List'

const RepoItem: React.FC<{
  repo: GitHub.Repo
  renderAction?: (repo: GitHub.Repo) => any
}> = (props) => {
  const { repo, renderAction } = props
  const theme = useTheme()

  return (
    <div
      className={classNames(
        'space-between',
        css`
          padding: 5px 2px;
          &:hover {
            background: ${theme.bgColorLight};
          }
        `,
      )}
      style={{
        marginBottom: 10,
      }}
    >
      <div>
        {repo.owner.login}/{repo.name}
      </div>
      <div>{renderAction(repo)}</div>
    </div>
  )
}

export const GitHubRepoList: React.FC<{
  renderRepoAction?: (repo: GitHub.Repo) => any
}> = (props) => {
  const { renderRepoAction } = props
  const swr = useGithubRepoPages()
  const { isValidating, loadMore, isReachingEnd, isLoadingInitial } = swr

  return (
    <>
      <List
        enhancedSWR={swr}
        renderItem={({ data: item }) => {
          return <RepoItem repo={item} key={item.id} renderAction={renderRepoAction} />
        }}
        showLoadingMore={false}
      />
      {!isReachingEnd && !isLoadingInitial && (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Button
            isLoading={isValidating}
            onClick={() => {
              loadMore()
            }}
            preset="lowKey"
          >
            Load more
          </Button>
        </div>
      )}
    </>
  )
}
