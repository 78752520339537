import React, { useCallback } from 'react'
import { useState } from 'react'
import { Modal, message } from 'antd'
import { CloneRepoForm } from '../components/repo-manager/CloneRepoForm'
import { useTheme } from '../services/theme'
import { usePortal } from './usePortal'
import { GitHub } from '../types/github'

export const useCloneRepo = (
  onSuccess?: (repo: GitHub.Repo) => void,
  onFailure?: (err: any) => void,
) => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  const start = useCallback(() => {
    if (open) {
      return
    }
    setOpen(true)
  }, [open])

  usePortal(
    <Modal
      width={theme.contentWidth}
      visible={open}
      onCancel={() => {
        setOpen(false)
      }}
      footer={null}
    >
      {open && (
        <CloneRepoForm
          onSuccess={(repo) => {
            onSuccess?.(repo)
            setOpen(false)
          }}
          onFailure={(err) => {
            onFailure?.(err)
            message.error(err.message)
          }}
        />
      )}
    </Modal>,
  )

  return {
    start,
  }
}
