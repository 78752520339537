import { css } from 'emotion'
import { Loading, Select } from 'lib-react-components'
import { useAsyncCall } from 'lib-react-hooks'
import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { handleServiceError } from '../../helpers/error'
import { useCommitOffsetCount } from '../../hooks/useCommitOffsetCount'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { useURLHelper } from '../../hooks/useURLHelper'
import {
  checkout,
  fetch,
  invalidateGitLocal,
  pull,
  useBranches,
  useGitHead,
  useGitStatus,
  useRepo,
} from '../../services/git'
import { useNotifyAppEvents } from '../../services/subscription'
import { IconArrowDown, IconDownload, IconGit } from '../_shared/Icon'

export const StatusSummary: React.FC<{}> = () => {
  const { owner, repo } = useRepoParams()
  const { getCommitEditorUrl } = useURLHelper()
  const { data: status } = useGitStatus(owner, repo)

  if (status.isClean) {
    return <></>
  }

  return (
    <Link to={getCommitEditorUrl()}>
      <div>{status.changesCount} 个修改</div>
    </Link>
  )
}

export const PullButton: React.FC<{}> = () => {
  const { owner, repo } = useRepoParams()
  const { data: head } = useGitHead(owner, repo)
  const count = useCommitOffsetCount(owner, repo)
  const { data: repoInfo } = useRepo(owner, repo)
  const ref = head.current || repoInfo.default_branch
  const notify = useNotifyAppEvents()

  const fetchThenPull = useCallback(async () => {
    await fetch(owner, repo, ref)
    await pull(owner, repo, ref)
    notify({
      key: 'pull',
      value: null,
    })
  }, [notify, owner, ref, repo])

  const { call: callPull, isLoading } = useAsyncCall(fetchThenPull, {
    callOnInit: false,
  })

  if (!count) {
    return <></>
  }

  if (isLoading) {
    return <Loading size="small" />
  }

  return (
    <div
      onClick={() => {
        callPull()
          .then(() => {
            invalidateGitLocal(owner, repo)
          })
          .catch(handleServiceError())
      }}
      className={css`
        cursor: pointer;
        display: flex;
        align-items: center;
      `}
    >
      {count === -1 ? (
        <IconDownload
          className={css`
            font-size: 16px;
            display: flex;
            align-items: center;
          `}
        />
      ) : (
        <div>
          {count}
          <IconArrowDown />
        </div>
      )}
    </div>
  )
}

export const WorkingTreeStatus: React.FC<{}> = (props) => {
  const { owner, repo } = useRepoParams()
  let {
    data: { current },
    revalidate: revalidateHead,
  } = useGitHead(owner, repo)
  const { data: branches } = useBranches(owner, repo)
  const notify = useNotifyAppEvents()
  const { call: callCheckout, isLoading } = useAsyncCall(checkout, {
    callOnInit: false,
  })
  const { data: repoInfo } = useRepo(owner, repo)

  if (!current) {
    current = repoInfo.default_branch
  }

  const handleCheckout = useCallback(
    (branch: string) => {
      return callCheckout({ owner, repo, branch })
        .then(() => {
          revalidateHead()
          notify({
            key: 'branchChange',
            value: branch,
          })
        })
        .catch(handleServiceError())
    },
    [callCheckout, notify, owner, repo, revalidateHead],
  )

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        & > * + * {
          margin-left: 15px !important;
        }
      `}
    >
      <div>
        <IconGit
          className={css`
            font-size: 15px;
            margin-right: 5px;
          `}
        />
        <Loading
          isLoading={isLoading}
          size="small"
          className={css`
            display: inline-block;
          `}
        >
          <Select
            antd={false}
            value={current}
            onChange={handleCheckout}
            options={branches.map((branch) => {
              return {
                value: branch.name,
                label: branch.name,
              }
            })}
            className={css`
              padding-top: 0;
              padding-bottom: 0;
            `}
          >
            {current}
          </Select>
        </Loading>
      </div>
      <PullButton />
      <StatusSummary />
    </div>
  )
}
