import React from 'react'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { SiteHeader } from '../_core/SiteHeader'
import { MainContent } from '../_shared/MainContent'
import { groupBy, map } from 'lodash'
import { useSetDocumentTitle } from 'lib-react-hooks'
import { useGitHubRepo } from '../../services/github'
import { useListArticles } from '../../services/docs'
import { Service } from '../../types/service'
import { RouterSearch } from '../_shared/RouterSearch'
import { PostTitle } from '../_shared/Post'
import { useURLHelper } from '../../hooks/useURLHelper'
import { css } from 'emotion'

const messageMap = {
  0: '这里没有货',
  1: '荒凉的可怜',
  10: '多思考啊少年，产出不咋地啊',
  20: '这种若即若离的感觉~',
  50: '有那么点意思',
  100: '文档输出小能手呀',
  200: '继续加油 ❤️',
  500: '里程碑时刻',
  1000: '居然真的能走这么远',
  1700: '写文章俨然已经成为一种习惯',
  3000: '初心未变',
  5000: '登峰造极！',
}

const getMessage = (len: number) => {
  const levels = Object.keys(messageMap).map(Number)

  if (len === 0) {
    return messageMap[0]
  }

  for (let i = 1; i < levels.length; i++) {
    if (len >= levels[i - 1] && len < levels[i]) {
      return messageMap[levels[i - 1]]
    }
  }

  return messageMap[levels[levels.length - 1]]
}

const ArchiveContent: React.FC<{}> = () => {
  useSetDocumentTitle('归档')

  const { owner, repo, ref } = useRepoParams()
  const { data: repoInfo } = useGitHubRepo(owner, repo)
  const url = useURLHelper(ref || repoInfo.default_branch)
  const {
    data: { items: articles },
  } = useListArticles(
    {
      limit: 9999,
      owner,
      repo,
      ref: ref || repoInfo.default_branch,
    },
    {
      suspense: true,
    },
  )
  const articlesWithDate = articles.filter((a) => a.date)
  const articlesWithoutDate = articles.filter((a) => !a.date)
  const notesByYear = groupBy(articlesWithDate || [], (item) => {
    return item.date.getFullYear()
  })

  const renderMonths = (items: Service.Article[]) => {
    const notesByMonth = groupBy(items || [], (item) => {
      return item.date.getMonth()
    })

    return map(notesByMonth, (group, month) => {
      return {
        group,
        month: +month,
      }
    })
      .sort((a, b) => (a.month > b.month ? -1 : 1))
      .map((item) => {
        return (
          <div key={item.month}>
            <div
              className={css`
                margin-bottom: 100px;
              `}
            >
              <div
                className={css`
                  margin-bottom: 20px;
                `}
              >
                <h3
                  className={css`
                    font-size: 50px;
                    margin: 10px 0 0;
                    line-height: 1;
                  `}
                >
                  {item.month + 1}月
                </h3>
                <div>{item.group.length} 篇文章</div>
              </div>
              <div>{renderArticles(item.group)}</div>
            </div>
          </div>
        )
      })
  }

  const renderArticles = (items: Service.Article[]) => {
    return items.map((item) => {
      return (
        <div key={item.path}>
          <PostTitle title={item.title} url={url.getBlobUrl(item.path)} />
        </div>
      )
    })
  }

  const renderYearTitle = ({ year, count }) => {
    return (
      <div
        className={css`
          margin-bottom: 60px;
        `}
      >
        <h2
          className={css`
            font-size: 150px;
            margin: 30px 0 0;
            line-height: 1;
          `}
        >
          {year}
        </h2>
        <div>{count} 篇文章</div>
      </div>
    )
  }

  return (
    <div
      className={css`
        padding-top: 30px;
      `}
    >
      <div>
        一共 {articles.length} 篇文章，{getMessage(articles.length)}
      </div>
      {map(notesByYear, (group, year) => {
        return {
          group,
          year,
        }
      })
        .sort((a, b) => (a.year > b.year ? -1 : 1))
        .map((item) => {
          return (
            <div key={item.year}>
              {renderYearTitle({
                year: item.year,
                count: item.group.length,
              })}
              {renderMonths(item.group)}
            </div>
          )
        })}
      <div>
        {renderYearTitle({
          year: '无日期',
          count: articlesWithoutDate.length,
        })}
        <div>{renderArticles(articlesWithoutDate)}</div>
      </div>
    </div>
  )
}

export const Archive: React.FC<{}> = () => {
  return (
    <>
      <SiteHeader
        left={
          <div
            className={css`
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 20px;
            `}
          >
            <div>归档</div>
            <RouterSearch />
          </div>
        }
      />
      <MainContent>
        <ArchiveContent />
      </MainContent>
    </>
  )
}
