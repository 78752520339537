import React, { HTMLAttributes } from 'react'
import { Menu, Dropdown } from 'antd'
import { Link } from 'react-router-dom'
import { css } from 'emotion'
import { Avatar, Fallback } from 'lib-react-components'
import { signOut, useUser } from '../../services/auth'
import { usePreferenceModal } from '../preference/usePreferenceModal'

export const UserMenu: React.FC<{
  items?: (React.ReactElement | string)[]
  fallback?: Fallback
} & HTMLAttributes<HTMLDivElement>> = ({ items = [], fallback, ...rest }) => {
  const { data: user } = useUser()
  const { setOpen } = usePreferenceModal()
  const finalItems = items.filter(Boolean)

  return (
    <Dropdown
      placement="bottomRight"
      overlay={
        <Menu
          style={{
            width: 100,
          }}
        >
          {finalItems.map((item, i) => {
            return <Menu.Item key={i}>{item}</Menu.Item>
          })}
          {!!finalItems.length && <Menu.Divider />}
          <Menu.Item>
            <Link to="/profile">个人资料</Link>
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              setOpen(true)
            }}
          >
            偏好设置
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item onClick={signOut}>退出</Menu.Item>
        </Menu>
      }
      trigger={['click']}
    >
      <Avatar
        name={user.name}
        url={user.avatar}
        size={30}
        round
        className={css`
          cursor: pointer;
        `}
        {...rest}
      />
    </Dropdown>
  )
}
