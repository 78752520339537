import React from 'react'
import { DEFAULT_SORT } from '../../config'
import { useQueryState } from '../../hooks/useQueryState'
import { SortSelect } from './SortSelect'

export const RouterSortSelect: React.FC<{}> = () => {
  const [query, setQuery] = useQueryState<{
    sort: string
  }>()
  const sort = query.sort || DEFAULT_SORT

  return (
    <SortSelect
      value={sort}
      onChange={(e) => {
        setQuery((query) => {
          return {
            ...query,
            sort: e,
          }
        })
      }}
    />
  )
}
