import React from 'react'
import { SiteHeader } from '../_core/SiteHeader'
import { MainContent } from '../_shared/MainContent'
import { useRepos, deleteRepo, syncRepoInfo } from '../../services/git'
import { Button, Loading } from 'lib-react-components'
import { useCloneRepo } from '../../hooks/useCloneRepo'
import { Menu, Modal } from 'antd'
import { notifyAPIError } from '../../helpers/network'
import { Link, useHistory } from 'react-router-dom'
import { getDraftUrl, getRepoUrl } from '../../helpers/urls'
import { useTheme } from '../../services/theme'
import { GitHub } from '../../types/github'
import { css } from 'emotion'
import { NoResult } from '../_shared/NoResult'
import { IconArrowRight } from '../_shared/Icon'
import { BasicDropdown } from '../_shared/BasicDropdown'
import { useAsyncCall, useSetDocumentTitle } from 'lib-react-hooks'

const RepoItem: React.FC<{
  repo: GitHub.Repo
  onItemDeleted?: (item: GitHub.Repo) => void
  onItemInfoSynced?: () => void
}> = ({ repo, onItemDeleted, onItemInfoSynced }) => {
  const label = `${repo.owner.login}/${repo.name}`
  const theme = useTheme()
  const history = useHistory()
  const { call, isLoading } = useAsyncCall(
    () => {
      return syncRepoInfo(repo.owner.login, repo.name)
    },
    {
      callOnInit: false,
    },
  )

  useSetDocumentTitle('我的笔记本')

  return (
    <div className="space-between">
      <Link
        style={{
          fontSize: 22,
          color: theme.textColor,
        }}
        to={getDraftUrl(repo.owner.login, repo.name)}
      >
        {label}
        {isLoading && (
          <Loading
            size="small"
            style={{
              marginLeft: 10,
            }}
          />
        )}
      </Link>
      <BasicDropdown
        items={[
          <Menu.Item
            onClick={() => {
              history.push(getRepoUrl(repo.owner.login, repo.name))
            }}
          >
            远程
          </Menu.Item>,
          <Menu.Divider />,
          <Menu.Item
            onClick={() => {
              call().then(() => {
                onItemInfoSynced?.()
              })
            }}
          >
            同步信息
          </Menu.Item>,
          <Menu.Item
            danger
            onClick={() => {
              Modal.confirm({
                content: `确定删除 ${label}?`,
                onOk: () => {
                  return deleteRepo(repo._id)
                    .then(() => {
                      onItemDeleted?.(repo)
                    })
                    .catch(notifyAPIError)
                },
              })
            }}
          >
            删除
          </Menu.Item>,
        ]}
      ></BasicDropdown>
    </div>
  )
}

const RepoManagerContent: React.FC<{}> = () => {
  const { data: repos, revalidate: revalidateRepos } = useRepos()
  const empty = repos.length === 0

  const { start } = useCloneRepo(() => {
    revalidateRepos()
  })

  return (
    <div>
      <div
        style={{
          marginBottom: 20,
        }}
      >
        <h1 className="page-title space-between y-center">
          <div>我的笔记本</div>
          <Button
            onClick={() => {
              start()
            }}
          >
            添加
          </Button>
        </h1>
      </div>
      {empty && <NoResult />}
      {repos.map((repo) => {
        return (
          <RepoItem
            onItemDeleted={() => {
              revalidateRepos()
            }}
            onItemInfoSynced={() => {
              revalidateRepos()
            }}
            repo={repo}
            key={repo.id}
          />
        )
      })}
      <div
        className={css`
          margin-top: 20px;
        `}
      >
        <Link
          to="/github"
          className={css`
            font-size: 18px;
          `}
        >
          更多{' '}
          <IconArrowRight
            className={css`
              font-size: 22px;
              position: relative;
              top: 2px;
            `}
          />
        </Link>
      </div>
    </div>
  )
}

export const RepoManager: React.FC<{}> = (props) => {
  return (
    <>
      <SiteHeader />
      <MainContent>
        <RepoManagerContent />
      </MainContent>
    </>
  )
}

export default RepoManager
