import { Modal } from 'antd'
import React, { useState } from 'react'
import { usePortal } from '../../hooks/usePortal'
import { Preference } from './Preference'

export const usePreferenceModal = () => {
  const [open, setOpen] = useState(false)

  usePortal(
    <Modal
      title="偏好设置"
      visible={open}
      onCancel={() => {
        setOpen(false)
      }}
      footer={null}
    >
      <Preference />
    </Modal>,
  )

  return {
    open,
    setOpen,
  }
}
