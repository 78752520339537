import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SiteHeader } from '../_core/SiteHeader'
import { MainContent } from '../_shared/MainContent'
import { useParams, useHistory } from 'react-router-dom'
import { useSetDocumentTitle, useWindowSize, useSync, SyncErrorCode } from 'lib-react-hooks'
import { useSetLastUsed } from '../../hooks/useSetLastUsed'
import MonacoEditor, { MonacoDiffEditor } from 'react-monaco-editor'
import { SlateEditor } from './SlateEditor'
import { useTheme } from '../../services/theme'
import { hash } from 'lib-core'
import { pick } from 'lodash'
import { Service } from '../../types/service'
import { Dropdown, Modal } from 'antd'
import { createDraft, getDraft, updateDraft } from '../../services/docs'
import { DraftMetaEditor } from './DraftMetaEditor'
import { IconError, IconMeta, IconWarning } from '../_shared/Icon'
import { css, cx } from 'emotion'
import { useURLHelper } from '../../hooks/useURLHelper'
import { Toc } from '../_shared/Toc'
import { extractHeadings } from '../../helpers/markdown'
import { Details } from '../_shared/Details'
import { usePreference } from '../preference/usePreference'
import { parsePath, toPath } from './utils'
import { useIsMobile } from '../../hooks/useIsMobile'
import { Button } from 'lib-react-components'

const getHash = (value: Service.Draft) => {
  return hash(pick(value, ['content', 'date', 'tags']))
}

type UseDraftSyncResult = ReturnType<typeof useDraftSync>

const useDraftSync = () => {
  const history = useHistory()
  const { owner, repo, _id } = useParams<{
    owner: string
    repo: string
    _id?: string
  }>()
  const { getEditorUrl } = useURLHelper()
  const isNew = _id === undefined

  const fetchRemote = useCallback(async (): Promise<Service.Draft> => {
    if (isNew) {
      return null
    }
    return getDraft({ owner, repo, slug: _id })
  }, [isNew, owner, repo, _id])

  const updateRemote = useCallback(
    async (state: Partial<Service.Draft>, prevRemote?: Service.Draft) => {
      if (isNew) {
        return createDraft({
          ...state,
          owner,
          repo,
        }).then((data) => {
          history.replace(getEditorUrl(data._id))
          return data
        })
      }

      if (!prevRemote) {
        throw new Error(`数据异常，未获取 remote`)
      }

      return updateDraft({
        ...state,
        _id,
        owner,
        repo,
        sha: prevRemote?.sha, // old sha
      }).catch((err) => {
        if (err.code === 1001) {
          return Promise.reject({
            message: err.message,
            code: SyncErrorCode.CONFLICT,
          })
        }
        throw err
      })
    },
    [isNew, _id, owner, repo, history, getEditorUrl],
  )

  const initialState: Partial<Service.Draft> = useMemo(() => {
    return {}
  }, [])

  const result = useSync<Service.Draft>({
    fetchRemote,
    updateRemote,
    initialState,
    getHash,
    pullOnFocus: false,
    initOnMount: false,
  })

  // 根据 title 自动更新 path
  const { remote, update } = result

  // dir 相关逻辑，都放在这里比较好维护
  const remoteTitle = remote?.title
  const { remoteDir, remoteFilename } = useMemo(() => {
    const { dir, filename } = parsePath(remote?.path)
    return {
      remoteDir: dir,
      remoteFilename: filename,
    }
  }, [remote])

  const updatePath = useCallback(
    (data: { dir?: string; title?: string }) => {
      if (!remote) {
        return
      }

      const { dir = remoteDir, title = remote.title } = data
      const nextPath = toPath(dir, title + '.md')

      if (nextPath !== remote.path) {
        update({
          path: nextPath,
        })
      }
    },
    [remote, remoteDir, update],
  )

  useEffect(() => {
    if (remoteTitle) {
      updatePath({
        title: remoteTitle,
      })
    }
  }, [remoteTitle, updatePath])

  return { ...result, remoteDir, remoteFilename, updatePath, isNew }
}

/**
 * saved  isSaving
 * true   true   不可能
 * false  true   保存中...
 * true   false  已保存
 * false  false  显示空
 */
const STATUS_MAP = {
  '0,1': '保存中...',
  '1,0': '已保存',
  '0,0': null,
}

const DraftStatus: React.FC<{
  changed: boolean
  isPushing: boolean
  conflict: boolean
  isPulling: boolean
  disabled?: boolean
  error?: {
    name?: string
    message?: string
    code?: number
  }
}> = (props) => {
  const { changed, isPushing, isPulling, conflict, error, disabled } = props
  const theme = useTheme()

  if (disabled) {
    return null
  }

  let value = STATUS_MAP[[!changed, isPushing].map((v) => (v ? 1 : 0)).join(',')]

  if (isPulling) {
    value = '拉取中...'
  }

  if (conflict) {
    value = (
      <div
        className={cx(
          css`
            margin-right: 10px;
            color: #cc7817;
            display: inline-block;
          `,
          'y-center',
        )}
      >
        <IconWarning
          className={css`
            font-size: 16px;
            margin-right: 3px;
            position: relative;
            top: 1px;
          `}
        />
        和远程冲突
      </div>
    )
  }

  if (!conflict && error) {
    value = (
      <div
        className={cx(
          css`
            color: #da2316;
          `,
          'y-center',
        )}
      >
        <IconError
          className={css`
            font-size: 16px;
            margin-right: 3px;
          `}
        />
        {error.name || 'Error'}
        {error.code ? ` ${error.code}` : ''}: {error.message || '未知错误'}
      </div>
    )
  }

  if (!value) {
    return null
  }

  return (
    <div
      className={cx(
        css`
          color: ${theme.textColorLight};
          margin-left: 5px;
        `,
        'y-center',
      )}
    >
      {value}
    </div>
  )
}

const DraftEditorHeader: React.FC<{
  sync: UseDraftSyncResult
}> = ({ sync }) => {
  const history = useHistory()
  const { getDraftUrl } = useURLHelper()
  const [showMetaEditor, setShowMetaEditor] = useState(false)
  const theme = useTheme()
  const {
    conflict,
    resolveConflict,
    error,
    changed,
    remote,
    local,
    isPulling,
    isPushing,
    update,
    updatePath,
    remoteFilename,
    remoteDir,
    isNew,
  } = sync

  return (
    <SiteHeader
      left={
        <div
          className={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <DraftStatus
            isPushing={isPushing}
            changed={changed || isNew}
            isPulling={isPulling}
            conflict={conflict}
            error={error}
          />
          {conflict && (
            <div>
              <Button
                size="small"
                preset="lowKey"
                onClick={() => {
                  Modal.confirm({
                    content: '确定标记为已解决么？',
                    onOk: () => {
                      resolveConflict()
                    },
                  })
                }}
              >
                标记为已解决
              </Button>
            </div>
          )}
        </div>
      }
      right={
        <>
          <Dropdown
            trigger={['click']}
            visible={showMetaEditor}
            onVisibleChange={setShowMetaEditor}
            overlay={
              <div
                className={css`
                  background-color: ${theme.bgColor};
                  box-shadow: ${theme.dark
                    ? 'rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.2) 0px 3px 6px,rgba(15, 15, 15, 0.4) 0px 9px 24px'
                    : 'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px'};
                  border-radius: 3px;
                `}
              >
                <DraftMetaEditor
                  filename={remoteFilename}
                  value={{
                    dir: remoteDir,
                    tags: local.tags,
                    date: local.date,
                  }}
                  onChange={(e) => {
                    if (e.dir !== undefined) {
                      updatePath({
                        dir: e.dir,
                      })
                      return
                    }
                    update(e)
                  }}
                />
              </div>
            }
          >
            <IconMeta
              style={{
                fontSize: 20,
                marginRight: 15,
                cursor: 'pointer',
                padding: 5,
              }}
            />
          </Dropdown>
          <Button
            style={{
              marginRight: 10,
            }}
            disabled={changed}
            onClick={() => {
              history.replace(getDraftUrl(remote.path))
            }}
            linkLike
          >
            完成
          </Button>
        </>
      }
    />
  )
}

const DraftEditorContent: React.FC<{
  sync: UseDraftSyncResult
  _id?: string
}> = ({ sync, _id }) => {
  const isNew = _id === undefined
  const {
    preference: { editor },
  } = usePreference()
  const { update, local, remote, isPulling, conflict, ready, init } = sync
  const { height } = useWindowSize()
  const headings = extractHeadings(local.content)
  const Editor = conflict ? MonacoDiffEditor : editor === 'monaco' ? MonacoEditor : SlateEditor
  const theme = useTheme()
  const mobileFlag = useIsMobile()
  const editorHeight = height - theme.appBarHeight
  // const editorWidth = theme.contentWidth

  useSetDocumentTitle(isPulling ? 'Loading' : isNew ? '新' : remote?.title ?? '无标题')

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div
        className={css`
          position: fixed;
          right: 0;
          top: calc(10px + var(--app-bar-height));
          width: calc((100vw - var(--content-width)) / 2 - 60px);
          box-sizing: border-box;
          padding-right: 30px;
        `}
      >
        {!!headings.length && !mobileFlag && (
          <Details
            summary="目录"
            defaultOpen
            className={css`
              margin-left: -20px;
            `}
          >
            <Toc headings={headings} />
          </Details>
        )}
      </div>
      <Editor
        height={editorHeight}
        width="100%"
        language="markdown"
        value={local.content || ''}
        original={remote?.content}
        theme={theme.dark ? 'vs-dark' : 'vs'}
        onChange={(md: string) => {
          // 默认最后一行保留回车，gray matter 会自动添加，这里保持这个行为以是的 sha 一致
          if (!md.endsWith('\n')) {
            md += '\n'
          }
          update({
            content: md,
          })
        }}
        options={{
          wordWrap: 'on',
          wordBasedSuggestions: false,
          renderSideBySide: false,
          minimap: {
            enabled: false,
          },
          lineNumbers: 'off',
          readOnly: !ready,
          tabSize: 2,
        }}
      />
    </>
  )
}

export const DraftEditor: React.FC<{}> = () => {
  useSetLastUsed()

  const sync = useDraftSync()

  const { _id } = useParams<{
    _id?: string
  }>()

  return (
    <>
      <DraftEditorHeader sync={sync} />
      <MainContent noSpacing>
        <DraftEditorContent sync={sync} _id={_id} />
      </MainContent>
    </>
  )
}

export default DraftEditor

DraftEditor.defaultProps = {
  isNew: false,
}
