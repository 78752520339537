import { Menu, Modal, Tooltip } from 'antd'
import { css, cx } from 'emotion'
import { Match } from 'lib-react-components'
import React, { Fragment, HTMLAttributes, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useTheme } from '../../services/theme'
import { PostStruct } from '../../types/client'
import { Service } from '../../types/service'
import { formatDate, getRelativeTime } from '../../utils'
import { BasicDropdown } from './BasicDropdown'
import { Tag } from './Tag'

// 这里相当于在偷懒，懒得透传，如果这些原子组件需要被其他地方依赖，还得加上 provider
// 但其实本身 post 就可以理解为一个 provider，我担忧的理由是，每个原子组件依赖的东西变得不明确
// 导致，比如说 meta 本来只依赖 date 和 updated，现在有了 context 它甚至可以依赖 onTitleClick 这样就变得极其不科学
// 其实对于原子组件而言，从 context 或者从 props 里取参数其实差别并不大，但对于父级组件而言，它担负着 props 透传的
// 职责，事情变得复杂

// 所以简而言之，context 会导致原子组件依赖不明确，不使用 context 会导致父级组件透传压力增加, 特别是层级变多了之后
// 但为了以后的代码可复用性，这种组件还是不要用 context

type WrapperProps = {
  preset?: 'card' | 'minimal'
}

export const PostWrapper: React.FC<WrapperProps & HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, className, preset, ...rest } = props
  const theme = useTheme()

  const clsMap = {
    card: css`
      border-radius: 7px;
      border: 1px solid ${theme.borderColor};
      padding: 15px 20px 10px;
      margin-bottom: 10px;
    `,
    minimal: css`
      padding: 10px 0;
      margin-bottom: 30px;
      border-bottom: 1px solid ${theme.borderColor};
    `,
  }

  return (
    <div {...rest} className={cx(className, clsMap[preset])}>
      {children}
    </div>
  )
}

PostWrapper.defaultProps = {
  preset: 'card',
}

type TitleProps = {
  onTitleClick?: () => void
  title: string
  url?: string
  highlights?: Service.ScopeSearchResult[]
}

export const PostTitle: React.FC<TitleProps> = (props) => {
  const { title, url, highlights, onTitleClick } = props
  const titleContent = highlights ? <Match text={title} matches={highlights} inline /> : title
  const theme = useTheme()

  return (
    <h1
      onClick={() => {
        onTitleClick?.()
      }}
      className={css`
        font-size: 20px;
        font-weight: bold;
        & a {
          color: ${theme.textColor};
        }
      `}
    >
      {url ? <Link to={url}>{titleContent}</Link> : <>{titleContent}</>}
    </h1>
  )
}

export const PostExcerpt: React.FC<{
  excerpt: string
  highlights?: Service.ScopeSearchResult[]
}> = (props) => {
  const { excerpt, highlights } = props

  const renderHighlights = () => {
    const max = 3
    const hasMore = highlights.length > max
    return (
      <>
        {highlights.slice(0, max)?.map((match, i) => {
          return <Match key={i} text={match.text} matches={[match]} />
        })}
        {hasMore && <div>...</div>}
      </>
    )
  }

  return (
    <div
      className={cx(
        css`
          font-size: 18px;
          margin-bottom: 10px;
        `,
        'wrap',
      )}
    >
      {highlights ? renderHighlights() : excerpt}
    </div>
  )
}

type DateProps = {
  date: Date
  updated: Date
  dateType?: 'date' | 'updated' | null
}

const joinWords = (a: string, b: string) => {
  const nonCnWords = /^[0-9|a-z|A-Z]/.test(b) || /[0-9|a-z|A-Z]$/.test(a)
  return [a, b].join(nonCnWords ? ' ' : '')
}

export const PostDate: React.FC<DateProps> = (props) => {
  const { date, updated, dateType } = props
  const [showUpdatedAt, setShowUpdatedAt] = useState(dateType === 'updated')
  const [pristine, setPristine] = useState(true)

  useEffect(() => {
    setShowUpdatedAt(dateType === 'updated')
  }, [dateType])

  const prefix = (text: string) => {
    if (pristine) {
      return null
    }
    return text
  }

  return (
    <>
      {dateType !== null && (
        <Tooltip
          placement="bottom"
          mouseEnterDelay={0.7}
          title={
            <div>
              <div>创建于</div>
              <div>{formatDate(date)}</div>
              <div>编辑于</div>
              <div>{formatDate(updated)}</div>
            </div>
          }
        >
          <div
            onClick={() => {
              setPristine(false)
              setShowUpdatedAt((v) => !v)
            }}
            style={{
              ...{
                cursor: 'pointer',
              },
              display: 'inline-block',
              fontSize: 16,
              color: '#999',
              userSelect: 'none',
            }}
          >
            {!showUpdatedAt && date && (
              <span>{joinWords(prefix('创建于'), getRelativeTime(date))}</span>
            )}
            {showUpdatedAt && updated && (
              <span>{joinWords(prefix('编辑于'), getRelativeTime(updated))}</span>
            )}
          </div>
        </Tooltip>
      )}
    </>
  )
}

PostDate.defaultProps = {
  dateType: 'updated',
}

export const PostTags: React.FC<{
  tags?: {
    tag: string
    url?: string
  }[]
  highlights?: Service.Matches['tags']
}> = (props) => {
  const { highlights, tags } = props

  if (!tags?.length) {
    return <></>
  }

  const renderTag = (tag: string) => {
    const match = highlights?.[tag]
    return <Tag>{match ? <Match text={tag} matches={match} inline /> : tag}</Tag>
  }

  return (
    <div
      style={{
        margin: '5px 0',
      }}
    >
      {tags.map(({ tag, url }) => {
        const tagContent = renderTag(tag)

        if (url) {
          return (
            <Link key={tag} to={url}>
              {tagContent}
            </Link>
          )
        }
        return <Fragment key={tag}>{tagContent}</Fragment>
      })}
    </div>
  )
}

type MetaProps = {
  onEdit?: (data?: PostStruct) => void
  onDelete?: (data?: PostStruct) => void
  date: Date
  updated: Date
  title?: string
  githubUrl?: string
  status?: 'added' | 'modified' | 'removed'
} & Pick<DateProps, 'dateType'>

export const PostMeta: React.FC<MetaProps> = (props) => {
  const { date, updated, onDelete, title, onEdit, dateType, githubUrl, status } = props
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const mobileFlag = useIsMobile()
  // const theme = useTheme()

  const handleDelete = () => {
    Modal.confirm({
      title: `确认删除 "${title}" 吗？`,
      icon: null,
      onOk: () => {
        return onDelete?.()
      },
    })
  }

  const ghBadge = githubUrl && status !== 'added' && (
    <a href={githubUrl} target="_blank" rel="noopener noreferrer">
      {/* <IconGitHub
        className={css`
          font-size: 16px;
          color: ${theme.textColorLight};
          &:hover {
            color: ${theme.textColorHover};
          }
        `}
      /> */}
      GitHub
    </a>
  )

  return (
    <div
      className={cx(
        'post__meta',
        'space-between',
        css`
          margin-top: 10px;
          height: 30px;
        `,
      )}
    >
      <div
        className={css`
          & > * {
            margin-right: 10px;
          }
        `}
      >
        <PostDate date={date} updated={updated} dateType={dateType} />
        {status && (
          <>
            {status === 'added' ? (
              <span
                className={css`
                  color: green;
                `}
              >
                A
              </span>
            ) : status === 'modified' ? (
              <span
                className={css`
                  color: orange;
                `}
              >
                M
              </span>
            ) : null}
          </>
        )}
      </div>
      <div
        className={cx(
          'post__meta__actions',
          css`
            & > * + * {
              margin-left: 15px !important;
            }
          `,
          (dropdownOpen || mobileFlag) &&
            css`
              visibility: visible !important;
            `,
        )}
      >
        {(onEdit || onDelete || ghBadge) && (
          <BasicDropdown
            onVisibleChange={setDropdownOpen}
            items={[
              onEdit && (
                <Menu.Item
                  onClick={() => {
                    onEdit()
                  }}
                >
                  编辑
                </Menu.Item>
              ),
              onEdit && (
                <Menu.Item danger onClick={handleDelete}>
                  删除
                </Menu.Item>
              ),
              ghBadge && <Menu.Divider />,
              ghBadge && <Menu.Item>{ghBadge}</Menu.Item>,
            ]}
          />
        )}
      </div>
    </div>
  )
}

type PostProps = {
  data: PostStruct
  getTagUrl?: (tag: string) => string
} & Pick<TitleProps, 'onTitleClick' | 'url'> &
  Pick<DateProps, 'dateType'> &
  Pick<MetaProps, 'onDelete' | 'onEdit' | 'githubUrl' | 'status'> &
  Pick<WrapperProps, 'preset'> &
  HTMLAttributes<HTMLDivElement>

export const Post: React.FC<PostProps> = (props) => {
  const {
    data: { title, excerpt, matches, date, updated, tags },
    url,
    onDelete,
    onEdit,
    onTitleClick,
    dateType,
    getTagUrl,
    githubUrl,
    status,
    preset,
    className,
    ...rest
  } = props

  const tagsWithUrl = tags?.map((tag) => {
    return {
      tag,
      url: getTagUrl?.(tag) ?? null,
    }
  })

  return (
    <PostWrapper
      {...rest}
      preset={preset}
      className={cx(
        css`
          & {
            .post__meta__actions {
              visibility: hidden;
            }
          }
          &:hover {
            .post__meta__actions {
              visibility: visible;
            }
          }
        `,
        className,
      )}
    >
      <PostTitle title={title} highlights={matches?.title} url={url} onTitleClick={onTitleClick} />
      <PostExcerpt excerpt={excerpt} highlights={matches?.content} />
      {tags && <PostTags tags={tagsWithUrl} highlights={matches?.tags} />}
      <PostMeta
        date={date}
        updated={updated}
        onDelete={onDelete}
        onEdit={onEdit}
        dateType={dateType}
        title={title}
        githubUrl={githubUrl}
        status={status}
      />
    </PostWrapper>
  )
}
