import React, { Suspense, useEffect } from 'react'
import { AppBar, Button, IconCross, IconSearch, Loading } from 'lib-react-components'
import { useHistory } from 'react-router-dom'
import { Logo } from '../_shared/Logo'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { RepoSelect } from './RepoSelect'
import { useURLHelper } from '../../hooks/useURLHelper'
import { LoginButton } from './Fallback'
import { Tag } from '../_shared/Tag'
import { UserMenu } from './UserMenu'
import { AuthGuard } from './AuthGuard'
import { isAuthError } from '../../helpers/error'
import { css, cx } from 'emotion'
import { RouterSearch } from '../_shared/RouterSearch'
import { atom, useRecoilState } from 'recoil'

type SiteHeaderProps = {
  leftPrefix?: any
  left?: any
  right?: any
  alwaysShow?: boolean
  overlay?: any
}

export const SiteHeader: React.FC<SiteHeaderProps> = (props) => {
  const { left, right, alwaysShow, leftPrefix, overlay } = props
  const history = useHistory()
  const { isRemote, getDraftUrl } = useURLHelper()
  const { owner } = useRepoParams()

  const renderMain = () => {
    return (
      <div
        className="y-center space-between"
        style={{
          height: '100%',
          // visibility: overlay ? 'hidden' : 'visible',
          display: overlay ? 'none' : 'flex',
        }}
      >
        <AuthGuard fallback={<Logo />}>
          <div
            className="y-center"
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {leftPrefix}
            {owner ? <RepoSelect /> : <Logo />}
            {isRemote && (
              <Tag>
                Remote{' '}
                <span
                  onClick={() => {
                    history.push(getDraftUrl())
                  }}
                  style={{
                    display: 'inline-block',
                    cursor: 'pointer',
                  }}
                >
                  <IconCross />
                </span>
              </Tag>
            )}
            <Suspense fallback={<Loading />}>{left}</Suspense>
          </div>
        </AuthGuard>
        <AuthGuard fallback={(err: any) => (isAuthError(err) ? <LoginButton /> : null)}>
          <div
            className="y-center"
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Suspense fallback={<Loading />}>{right}</Suspense>
            <UserMenu
              fallback={<LoginButton />}
              style={{
                marginLeft: 15,
              }}
            />
          </div>
        </AuthGuard>
      </div>
    )
  }

  return (
    <AppBar show={alwaysShow}>
      {overlay && (
        <div
          className={cx(
            'y-center',
            css`
              height: 100%;
            `,
          )}
        >
          {overlay}
        </div>
      )}
      {renderMain()}
    </AppBar>
  )
}

SiteHeader.defaultProps = {
  alwaysShow: true,
}

const headerState = atom<{
  showOverlay: boolean
}>({
  key: 'SiteHeaderWithSearch',
  default: {
    showOverlay: false,
  },
})

const HeaderSearch: React.FC<{}> = () => {
  const [, setState] = useRecoilState(headerState)

  return (
    <>
      <RouterSearch
        className={css`
          width: 100%;
        `}
      />
      <Button
        linkLike
        onClick={() => {
          setState({
            showOverlay: false,
          })
        }}
        className={css`
          margin-left: 10px;
        `}
      >
        取消
      </Button>
    </>
  )
}

export const SearchButton: React.FC<{}> = (props) => {
  const [, setState] = useRecoilState(headerState)

  return (
    <IconSearch
      className={css`
        font-size: 18px;
      `}
      onClick={() => {
        setState({
          showOverlay: true,
        })
      }}
    />
  )
}

export const SiteHeaderWithSearch: React.FC<Omit<SiteHeaderProps, 'overlay'>> = (props) => {
  const [{ showOverlay }, setState] = useRecoilState(headerState)

  useEffect(() => {
    return () => {
      setState({
        showOverlay: false,
      })
    }
  }, [setState])

  const renderOverlay = () => {
    return showOverlay && <HeaderSearch />
  }

  return <SiteHeader {...props} overlay={renderOverlay()} />
}
