import React, { useState } from 'react'
import { Dropdown } from 'antd'
import { useRecentRepos } from '../../services/docs'
import { Loading } from 'lib-react-components'
import { Link } from 'react-router-dom'
import { css, cx } from 'emotion'
import { useTheme } from '../../services/theme'
import { IconSwitch } from '../_shared/Icon'
import { useURLHelper } from '../../hooks/useURLHelper'
import { getDraftUrl, getRepoUrl } from '../../helpers/urls'
import { Service } from '../../types/service'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { useRepo } from '../../services/git'

const MAX_RECENT = 10
const emptyArray: Service.RecentRepo[] = []

const RepoList: React.FC<{
  active: string
  onItemChange: (key: string) => void
}> = (props) => {
  const url = useURLHelper()
  const { active, onItemChange } = props
  const theme = useTheme()
  const { data = emptyArray, isValidating } = useRecentRepos({
    suspense: false,
  })
  const isLoading = isValidating && data === emptyArray
  const repoList = data.slice(0, MAX_RECENT)

  const linkCls = (isActive: boolean) => css`
    color: ${isActive ? theme.linkColorActive : theme.textColor};
    &:hover {
      color: ${isActive ? theme.linkColorActive : theme.textColorHover};
    }
  `

  return (
    <div
      className={css`
        background-color: ${theme.bgColor};
        box-shadow: ${theme.dark
          ? 'rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.2) 0px 3px 6px,rgba(15, 15, 15, 0.4) 0px 9px 24px'
          : 'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px'};
        border-radius: 3px;
        font-size: 16px;
        width: 200px;
      `}
    >
      {isLoading ? (
        <div
          className={css`
            padding: 20px 0;
          `}
        >
          <Loading center />
        </div>
      ) : (
        <>
          {Boolean(repoList.length) && (
            <div
              className={css`
                border-bottom: 1px solid ${theme.borderColor};
                padding: 5px 0;
              `}
            >
              {!isLoading &&
                repoList.map((item) => {
                  const key = `${item.owner.login}/${item.name}`
                  const isActive = active === key
                  const to = url.isRemote
                    ? getRepoUrl(item.owner.login, item.name)
                    : getDraftUrl(item.owner.login, item.name)

                  return (
                    <Link
                      className={linkCls(isActive)}
                      to={to}
                      key={item._id}
                      onClick={() => {
                        onItemChange(key)
                      }}
                    >
                      <div
                        className={css`
                          padding: 5px 20px;
                        `}
                      >
                        {item.name}
                      </div>
                    </Link>
                  )
                })}
            </div>
          )}
          <Link className={linkCls(false)} to="/repos">
            <div
              className={css`
                padding: 10px 20px;
              `}
            >
              管理仓库
            </div>
          </Link>
        </>
      )}
    </div>
  )
}

export const RepoSelect: React.FC<{}> = () => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const url = useURLHelper()
  const { owner, repo } = useRepoParams()
  const active = `${owner}/${repo}`
  const { error } = useRepo(owner, repo, {
    suspense: false,
  })
  const indexUrl = error?.statusCode === 404 ? '/repos' : url.getIndexUrl()

  if (!owner || !repo) {
    console.error(`owner and repo are required`)
    return <></>
  }

  return (
    <div
      className={cx(
        css`
          display: flex;
          direction: row;
        `,
        'y-center',
      )}
    >
      <div>
        <h1
          style={{
            margin: 0,
            fontSize: 20,
            lineHeight: 1,
            color: theme.textColor,
          }}
        >
          <Link
            style={{
              color: theme.textColor,
            }}
            to={indexUrl}
            className="no-wrap"
          >
            {repo}
          </Link>
        </h1>
      </div>
      <Dropdown
        overlay={
          <RepoList
            active={active}
            onItemChange={() => {
              setOpen(false)
            }}
          />
        }
        visible={open}
        onVisibleChange={(e) => {
          setOpen(e)
        }}
        trigger={['click']}
      >
        <div
          className={css`
            cursor: pointer;
            font-size: 20px;
          `}
        >
          <IconSwitch
            style={{
              fontSize: 14,
              opacity: 0.5,
              padding: '0 10px',
            }}
          />
        </div>
      </Dropdown>
    </div>
  )
}
