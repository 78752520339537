import React from 'react'
import { SiteHeader } from '../_core/SiteHeader'
import { useGitHubRepo } from '../../services/github'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { MainContent } from '../_shared/MainContent'
import { useListDraftsLegacy, deleteDraftLegacy } from '../../services/docs'
import { NoResult } from '../_shared/NoResult'
import { DraftCardLegacy } from './DraftCardLegacy'
import { useSetDocumentTitle } from 'lib-react-hooks'

/**
 * @deprecated
 */
export const DraftsLegacy: React.FC<{}> = () => {
  const query = useRepoParams()
  const { repo, owner } = query

  useSetDocumentTitle('Drafts')

  // data hooks
  const { data: repoInfo } = useGitHubRepo(owner, repo)
  const { data: drafts, revalidate } = useListDraftsLegacy({
    repoId: repoInfo.id,
    ownerId: repoInfo.owner.id,
  })

  const isEmpty = drafts.length === 0

  return (
    <>
      <SiteHeader />
      <MainContent>
        <div
          style={{
            paddingTop: 20,
            marginBottom: 40,
          }}
        >
          {isEmpty && <NoResult title="没有草稿" />}
          {drafts.map((draft) => {
            return (
              <DraftCardLegacy
                draft={draft}
                key={draft._id}
                onDelete={() => {
                  deleteDraftLegacy(draft._id).then(() => {
                    revalidate()
                  })
                }}
              />
            )
          })}
        </div>
      </MainContent>
    </>
  )
}

export default DraftsLegacy
