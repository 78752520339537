import React, { Suspense } from 'react'
import { LoadingScreen } from 'lib-react-components'
import { useTheme } from '../../services/theme'
import { StatusBar } from './StatusBar'
import { ErrorBoundary } from '../_core/ErrorBoundary'
import { AuthGuard } from '../_core/AuthGuard'
import { css } from 'emotion'

export const MainContent: React.FC<{
  noSpacing?: boolean
  full?: boolean
  statusBar?: any
  requireAuth?: boolean
}> = (props) => {
  const { children, full, statusBar, requireAuth, noSpacing } = props
  const { appBarHeight, statusBarHeight, contentWidth } = useTheme()

  return (
    <div
      data-component="MainContent"
      style={{
        margin: 'auto',
        padding: !full && '0 20px',
        paddingTop: appBarHeight,
        paddingBottom: statusBar && statusBarHeight,
        width: '100%',
        maxWidth: full ? '100%' : contentWidth,
      }}
    >
      {!noSpacing && (
        <div
          // 和 Loading margin collapse
          className={css`
            margin-bottom: 20px;
            height: 1px;
            margin-top: -1px;
          `}
        />
      )}
      <ErrorBoundary details="来自: MainContent">
        <AuthGuard loading={<LoadingScreen text="检查授权..." />} noCheck={!requireAuth}>
          <Suspense fallback={<LoadingScreen text="加载数据..." />}>
            {children}
            {statusBar && <StatusBar>{statusBar}</StatusBar>}
          </Suspense>
        </AuthGuard>
      </ErrorBoundary>
    </div>
  )
}

MainContent.defaultProps = {
  requireAuth: true,
  noSpacing: false,
}
