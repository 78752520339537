import React from 'react'
import { Link } from 'react-router-dom'

export const Tag: React.FC<{}> = (props) => {
  return (
    <span
      style={{
        display: 'inline-block',
        padding: '3px 8px',
        borderRadius: 100,
        background: '#ddd',
        marginRight: 5,
        fontSize: 12,
        color: '#666',
      }}
    >
      {props.children}
    </span>
  )
}

export const Tags: React.FC<{
  tags: {
    tag: string
    url: string
  }[]
  renderTag?: (props: { tag: string }) => any
}> = (props) => {
  return (
    <div
      style={{
        margin: '15px 0 0',
      }}
    >
      {props.tags.map((t) => {
        return (
          <Link key={t.tag} to={t.url}>
            {props.renderTag ? props.renderTag(t) : <Tag>{t.tag}</Tag>}
          </Link>
        )
      })}
    </div>
  )
}
