import React from 'react'
import { Button } from 'lib-react-components'
import { useHistory } from 'react-router-dom'
import { IconBack } from '../../_shared/Icon'
import { getDraftUrlLegacy } from '../../../helpers/urls'

export const IDENavBar: React.FC<{
  backPath: string
  owner: string
  repo: string
  leftSlot: any
  rightSlot: any
}> = (props) => {
  const { backPath, owner, repo, leftSlot, rightSlot } = props
  const history = useHistory()

  return (
    <div
      style={{
        height: 40,
        width: '100%',
        background: '#222',
      }}
      className="y-center"
    >
      <div
        className="space-between"
        style={{
          width: '100%',
        }}
      >
        <div
          className="y-center"
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Button
            linkLike
            onClick={() => {
              // TODO 如果一开始就是在这个界面可能跳转到站外
              history.push(backPath)
            }}
          >
            <IconBack
              style={{
                display: 'inline-block',
              }}
            />{' '}
            Back
          </Button>
          {leftSlot}
        </div>
        <div>
          {rightSlot}
          <Button
            linkLike
            style={{
              marginRight: 20,
            }}
            onClick={() => {
              history.push(getDraftUrlLegacy(owner, repo, 'new'))
              // TODO
              window.location.reload()
            }}
          >
            Create
          </Button>
        </div>
      </div>
    </div>
  )
}
