import React, { useEffect, useState } from 'react'
import { Loading, ErrorInfo } from 'lib-react-components'
import { useParams, useHistory } from 'react-router-dom'
import { getDraftUrlLegacy } from '../../helpers/urls'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { blobContentToDraft } from '../../helpers/markdown'
import { createDraftLegacy } from '../../services/docs'
import { getGitBlob } from '../../services/git'
import { useGitHubRepo } from '../../services/github'
import { DraftLegacy } from '../../types/types'
import { useAutoUpdateRef, useSetDocumentTitle } from 'lib-react-hooks'

const useCreateDraftFromBlob = (
  blobSha: string,
  onDraftCreated: (err: Error, draft: DraftLegacy) => void,
) => {
  const query = useRepoParams()
  const { data: repoInfo } = useGitHubRepo(query.owner, query.repo)
  const onDraftCreatedRef = useAutoUpdateRef(onDraftCreated)
  const [result, setResult] = useState<{
    data: DraftLegacy
    error: Error
    isLoading: boolean
  }>({
    data: null,
    error: null,
    isLoading: true,
  })

  useEffect(() => {
    getGitBlob(query.owner, query.repo, blobSha)
      .then((blob) => {
        return createDraftLegacy({
          ...blobContentToDraft(blob._text),
          ownerId: repoInfo.owner.id,
          repoId: repoInfo.id,
          path: blob.path as string,
        })
      })
      .then((data) => {
        onDraftCreatedRef.current(null, data)
        setResult({
          error: null,
          data,
          isLoading: false,
        })
      })
      .catch((err) => {
        onDraftCreatedRef.current(err, null)
        setResult({
          error: err,
          data: null,
          isLoading: false,
        })
      })
  }, [query, blobSha, repoInfo, onDraftCreatedRef])

  return result
}

export const Fork: React.FC<{}> = (props) => {
  const history = useHistory()
  const { blobSha } = useParams<{
    blobSha: string
  }>()
  const query = useRepoParams()
  // TODO 异常时候的 title 处理
  useSetDocumentTitle('Loading...')

  const { error } = useCreateDraftFromBlob(blobSha, (err, draft) => {
    if (!err) {
      history.replace(getDraftUrlLegacy(query.owner, query.repo, draft._id))
    }
  })

  if (error) {
    return <ErrorInfo error={error} />
  }

  return (
    <Loading
      style={{
        margin: '20px 0',
      }}
    />
  )
}

export default Fork
