import { pick, memoize } from 'lodash'
import matter from 'gray-matter'
import { DraftLegacy } from '../types/types'
import {
  ensureArray,
  extractHeadings as _extractHeadings,
  determineLineBreak,
  generateExcerpt,
} from 'lib-core'

export const extractHeadings = memoize(_extractHeadings)

export const draftToBlobContent = (draft: Partial<DraftLegacy>) => {
  const { content, ...meta } = draft
  // title 偏向于保存到 content 里面
  return matter.stringify(content, pick(meta, ['tags', 'date', 'updated']))
}

export const blobContentToDraft = (
  blobContent: string,
): Pick<DraftLegacy, 'date' | 'updated' | 'content' | 'tags'> => {
  let { data: attrs, content } = matter(blobContent)
  let { title, tags, date, updated } = attrs

  tags = Array.from(new Set(ensureArray(tags)))

  if (title) {
    // 这里为了兼容之前使用 frontmatter 存储 title 的 md
    content = `#${title}\n\n${content}`
  }

  return {
    date,
    updated,
    content,
    tags,
  }
}

export const parseContent = (content: string) => {
  const lineBreak = determineLineBreak(content)
  const lines = content.split(lineBreak)
  let title: string
  let contentIdx = 0

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim()

    if (line !== '') {
      title = generateExcerpt(line).content
      contentIdx = i + 1
      break
    }
  }

  return {
    title,
    content: lines.slice(contentIdx).join(lineBreak),
  }
}

export const extractTitleFromContent = (content: string) => {
  return parseContent(content).title
}
