import { omitBy } from 'lodash'
import qs from 'qs'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from './useQuery'

type SetQueryOptions = {
  replace?: Boolean
  pathname?: string
}

export const useQueryState = <T extends Record<string, unknown>>(
  initialValue?: T,
): [T, (updater: (q: any) => T, options?: SetQueryOptions) => void] => {
  const query = useQuery<T>()
  const history = useHistory()

  const setQuery = useCallback(
    (updater: (q: any) => T, { replace = false, pathname }: SetQueryOptions = {}) => {
      const nextQuery = updater(query)
      const queryStr = qs.stringify(omitBy(nextQuery, (v) => v === undefined || v === ''))
      const nextUrl = `${pathname || history.location.pathname}${queryStr ? `?${queryStr}` : ''}`

      if (replace) {
        history.replace(nextUrl)
      } else {
        history.push(nextUrl)
      }
    },
    [history, query],
  )

  return [query, setQuery]
}
