import React from 'react'
import { createKvStorageAsync } from 'lib-core'
import { cache as swrCache } from 'swr-fork'
import { processResponse } from '../../helpers/data'
import { ENABLE_SERVICE_CACHE } from '../../config'
import { memoize } from 'lodash'
import { suspend } from 'lib-react-components'

const apiDataStore = createKvStorageAsync<Record<string, unknown>>('api-data')

const loadCache = async () => {
  // TODO keys 获取超级慢
  const cachedKeys = await apiDataStore.keys()

  const cachePromise = Promise.all(
    cachedKeys.map(async (key) => {
      const data = await apiDataStore.get(key)
      swrCache.set(key, processResponse(data), false)
    }),
  )

  return cachePromise
}

const initCache = memoize(async () => {
  const cachePromise = loadCache()

  swrCache.subscribe((e) => {
    if (
      e.type === 'set' &&
      !e.key.startsWith('validating@') &&
      !e.key.startsWith('err@') &&
      !e.key.startsWith('size@') &&
      !e.key.startsWith('context@')
    ) {
      if (e.key.startsWith('arg@"many"@')) {
        apiDataStore.set(e.key, (e.value as any[]).slice(0, 1))
      } else {
        apiDataStore.set(e.key, e.value)
      }
    }
  })

  await cachePromise
})

export const Cache: React.FC<{}> = (props) => {
  suspend(ENABLE_SERVICE_CACHE && initCache())

  return <>{props.children}</>
}
