import { Radio } from 'antd'
import { css } from 'emotion'
import { Select } from 'lib-react-components'
import React from 'react'
import { usePreference } from './usePreference'

const colorOptions = [
  {
    label: '跟随系统',
    value: '',
  },
  {
    label: '浅色',
    value: 'light',
  },
  {
    label: '深色',
    value: 'dark',
  },
]

const editorOptions = [
  {
    label: 'Monaco',
    value: 'monaco',
  },
  {
    label: 'Slate',
    value: 'slate',
  },
]

export const Preference: React.FC<{}> = (props) => {
  const { preference, update } = usePreference()
  const labelCls = css`
    width: 60px;
    display: inline-block;
  `

  return (
    <div>
      <div
        style={{
          marginBottom: 10,
        }}
      >
        <div className={labelCls}>主题</div>
        <Radio.Group
          value={preference.colorScheme}
          onChange={(e) => {
            update({
              colorScheme: e.target.value,
            })
          }}
          options={colorOptions}
          optionType="button"
        />
      </div>
      <div>
        <div className={labelCls}>编辑器</div>
        <Select
          value={preference.editor}
          onChange={(e) => {
            update({
              editor: e as any,
            })
          }}
          options={editorOptions}
        />
      </div>
    </div>
  )
}
