import { useMemo, useEffect } from 'react'
import { getPreference, updatePreference } from '../components/preference/service'
import { fetchRepo } from '../services/git'

export const useLastRepo = () => {
  const last = useMemo(() => {
    return getPreference().lastUsed
  }, [])

  useEffect(() => {
    if (last) {
      fetchRepo(last.owner, last.repo)
        .then(() => {
          return last
        })
        .catch((err) => {
          if (err.name === 'NotFoundError') {
            updatePreference({
              lastUsed: null,
            })
          }
        })
    }
  }, [last])

  return last
}
