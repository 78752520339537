import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Select, NavBar } from 'lib-react-components'
import { useRepo, useBranches } from '../../services/git'
import { useEffect } from 'react'
import { markRepoViewed, useArticlePages } from '../../services/docs'
import { MainContent } from '../_shared/MainContent'
import { getRepoUrl } from '../../helpers/urls'
import { SearchButton, SiteHeaderWithSearch } from '../_core/SiteHeader'
import { isSha, shortenSha } from '../../utils'
import { useSetLastUsed } from '../../hooks/useSetLastUsed'
import { List } from '../_shared/List'
import { Post } from '../_shared/Post'
import { useURLHelper } from '../../hooks/useURLHelper'
import { RouterSearch } from '../_shared/RouterSearch'
import { RouterSortSelect } from '../_core/RouterSortSelect'
import { Service } from '../../types/service'
import { RouterFilter } from '../_core/RouterFilter'
import { useQueryState } from '../../hooks/useQueryState'
import { DEFAULT_SORT } from '../../config'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { BasicDropdown } from '../_shared/BasicDropdown'
import { Menu } from 'antd'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useScrollEnd, useSetDocumentTitle } from 'lib-react-hooks'

const BranchSelect: React.FC<{
  branches: Service.Ref[]
  activeRef: string
  onChange: (e: string) => void
}> = (props) => {
  const { branches, activeRef, onChange } = props
  const branchOptions = (isSha(activeRef)
    ? [
        {
          name: activeRef,
          value: shortenSha(activeRef),
        },
        ...branches,
      ]
    : branches
  ).map((b) => {
    return {
      // @ts-ignore
      label: b.value || b.name,
      value: b.name,
    }
  })

  return (
    <Select
      style={{
        maxWidth: 200,
      }}
      value={activeRef}
      options={branchOptions}
      onChange={onChange}
    />
  )
}

const RepoHeaderRight: React.FC<{}> = () => {
  const [query] = useQueryState<{
    search: string
    sort: string
    tag: string
  }>()
  const { repo, owner, ref: refParam } = useRepoParams()
  const { data: repoInfo } = useRepo(owner, repo)
  const ref = refParam || repoInfo.default_branch
  const url = useURLHelper(ref)
  const history = useHistory()
  const { data: branches } = useBranches(owner, repo)
  const mobileFlag = useIsMobile()

  return (
    <>
      <NavBar
        items={[
          !mobileFlag && (
            <BranchSelect
              branches={branches}
              activeRef={ref}
              onChange={(e) => {
                history.push(getRepoUrl(owner, repo, e, query))
              }}
            />
          ),
          !mobileFlag && <RouterSortSelect />,
          <SearchButton />,
          <BasicDropdown
            items={[
              {
                content: <Link to={url.getDraftUrl()}>草稿</Link>,
              },
              {
                content: <Link to={url.getLogsUrl()}>日志</Link>,
              },
              <Menu.Divider />,
              {
                content: owner && repo && <Link to={`/archive/${owner}/${repo}/${ref}`}>归档</Link>,
              },
            ]}
          />,
        ]}
      ></NavBar>
    </>
  )
}

export const RepoContent: React.FC<{}> = (props) => {
  const [query] = useQueryState<{
    search: string
    sort: string
    tag: string
  }>()
  const { repo, owner, ref: refParam } = useRepoParams()
  const { data: repoInfo } = useRepo(owner, repo)
  const ref = refParam || repoInfo.default_branch
  const url = useURLHelper(ref)
  const { sort = DEFAULT_SORT, search, tag } = query
  const swr = useArticlePages({
    ref,
    owner,
    repo,
    search,
    tag,
    sort,
  })

  const repoId = repoInfo.id

  useSetDocumentTitle(`${owner}/${repo}`)

  useScrollEnd(swr.loadMore, {
    margin: 10,
    debounceWait: 100,
  })

  useEffect(() => {
    markRepoViewed(repoId)
  }, [repoId])

  return (
    <div>
      <RouterFilter />
      <List
        enhancedSWR={swr}
        itemComponent={Post}
        preprocessItemProps={(data) => {
          return {
            data,
            url: url.getBlobUrl(data.path),
            dateType: (sort.startsWith('updated') ? 'updated' : 'date') as any,
            getTagUrl: (tag) => {
              return url.getRemoteIndex() + `?tag=${tag}`
            },
            githubUrl: url.getGitHubBlobUrl(data.path),
          }
        }}
      />
    </div>
  )
}

export const Repo: React.FC<{}> = () => {
  useSetLastUsed()

  const mobileFlag = useIsMobile()

  return (
    <>
      <SiteHeaderWithSearch left={!mobileFlag && <RouterSearch />} right={<RepoHeaderRight />} />
      <MainContent>
        <RepoContent />
      </MainContent>
    </>
  )
}
