import useSWR, { ConfigInterface, useSWRInfinite } from 'swr-fork'
import { github } from '../helpers/network'
import qs from 'qs'
import base64 from 'js-base64'
import { HeadersSymbol } from 'lib-core'
import { useEnhancedSWRPages } from '../hooks/useEnhancedSWRPages'
import { parseGitHubHeaderLink } from '../helpers/data'
import { GitHub } from '../types/github'

export const useGitHubRepo = (owner: string, repo: string, swrConfig: ConfigInterface = {}) => {
  return useSWR<GitHub.Repo>(owner && repo && `repos/${owner}/${repo}`, github, {
    suspense: true,
    revalidateOnFocus: false,
    ...swrConfig,
  })
}

export const useGitHubUser = (swrConfig: ConfigInterface = {}) => {
  return useSWR<GitHub.User>(`user`, github, {
    suspense: true,
    revalidateOnFocus: false,
    ...swrConfig,
  })
}

export const useGitHubCommits = (owner: string, repo: string, sha?: string) => {
  return useSWR<GitHub.CommitItem[]>(
    `repos/${owner}/${repo}/commits?${qs.stringify({
      sha,
    })}`,
    github,
    {
      suspense: true,
      revalidateOnFocus: true,
    },
  )
}

export const useGitHubBranches = (owner: string, repo: string) => {
  return useSWR<GitHub.Branch[]>(
    owner &&
      repo &&
      `repos/${owner}/${repo}/branches?${qs.stringify({
        page: 1,
        per_page: 100,
      })}`,
    github,
    {
      suspense: true,
      revalidateOnFocus: false,
    },
  )
}

export const useGithubRepoPages = () => {
  const swr = useSWRInfinite<{
    items: GitHub.Repo[]
    meta: {
      next?: {
        url: string
        query: Record<string, string>
        rel: string
      }
      last?: {
        url: string
        query: Record<string, string>
        rel: string
      }
    }
    next: number
  }>(
    (n, prev) => {
      if (prev && !prev.next) {
        return null
      }
      return `user/repos?${qs.stringify({
        type: 'all',
        per_page: 100,
        page: prev?.next || 1,
        sort: 'updated',
      })}`
    },
    async (url: string) => {
      const data = await github(url)
      const headers = data[HeadersSymbol]
      const meta = parseGitHubHeaderLink(headers.link)
      const next = Number(meta?.next?.query?.page) || null

      return {
        meta,
        next,
        items: data,
      }
    },
  )
  return useEnhancedSWRPages(swr)
}

type Author = {
  name: string
  email: string
}

export const commit = (
  config: {
    owner: string
    repo: string
    path: string
  },
  commit: {
    message: string
    content: string
    sha?: string
    branch?: string
    committer?: Author
    author?: Author
  },
) => {
  return github.put(`/repos/${config.owner}/${config.repo}/contents/${config.path}`, {
    ...commit,
    // TODO 更通用的做法
    content: base64.Base64.encode(commit.content),
  })
}

// https://docs.github.com/en/rest/reference/repos#get-repository-content
export const getGitHubRawContent = async (owner: string, repo: string, filePath: string) => {
  return github<GitHub.RawContentFile>(
    `repos/${owner}/${repo}/contents/${filePath.startsWith('/') ? filePath.substr(1) : filePath}`,
    {
      headers: {
        Accept: 'application/vnd.github.v3.base64',
      },
    },
  )
}

const decodeList = ['.md', '.js', '.jsx', '.css', '.tsx', '.scss', '.html', '.json']

export const useGitHubRawContent = (owner: string, repo: string, filePath: string) => {
  return useSWR<GitHub.RawContentFile>(
    filePath &&
      `repos/${owner}/${repo}/contents/${filePath.startsWith('/') ? filePath.substr(1) : filePath}`,
    (url) =>
      github
        .get(url)
        .then((data) => {
          const shouldDecode = decodeList.some((suffix) => data?.path?.endsWith(suffix))
          return {
            ...data,
            content: shouldDecode ? base64.Base64.decode(data.content) : data.content,
          }
        })
        .catch((error) => {
          return { content: null, error }
        }),
    {
      suspense: false,
      revalidateOnFocus: false,
    },
  )
}
