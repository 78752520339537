import { Button, ErrorInfo, ErrorStruct } from 'lib-react-components'
import { useSetDocumentTitle } from 'lib-react-hooks'
import React from 'react'
import { MainContent } from '../_shared/MainContent'
import { SiteHeader } from './SiteHeader'

const openOAuth = () => {
  window.location.href = `${process.env.REACT_APP_SIGN_IN_URL}?from=${encodeURIComponent(
    window.location.href,
  )}`
}

export const LoginButton: React.FC<{}> = () => {
  return (
    <Button preset="dark" onClick={openOAuth}>
      Login with GitHub
    </Button>
  )
}

export const UnauthorizedFallback: React.FC<{ error?: ErrorStruct }> = (props) => {
  useSetDocumentTitle('Sign in')

  return (
    <div>
      <h1
        style={{
          fontSize: 40,
          margin: 0,
          padding: 0,
          marginTop: 70,
          marginBottom: 20,
        }}
      >
        Sign in to git<strong>docs</strong>
      </h1>
      <div>
        <Button size="large" preset="dark" onClick={openOAuth}>
          Continue with GitHub
        </Button>
      </div>
    </div>
  )
}

export const NotFound: React.FC<{}> = () => {
  useSetDocumentTitle('Not found')

  return (
    <>
      <SiteHeader />
      <MainContent requireAuth={false}>
        <ErrorInfo
          error={{
            name: '404',
            message: 'This page is not found',
          }}
        />
      </MainContent>
    </>
  )
}
