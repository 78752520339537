import React, {
  useCallback,
  useEffect,
  createContext,
  FC,
  useState,
  useRef,
  Fragment,
  useContext,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from 'react'
import { createPortal } from 'react-dom'

const PortalCtx = createContext<{
  createCell: () => {
    render: (el: any) => void
    destroy: () => void
  }
}>(null)

const Cell = forwardRef((props, ref) => {
  const [children, setChildren] = useState(null)

  useImperativeHandle(ref, () => {
    return {
      setChildren,
    }
  })

  return <>{children}</>
})

type CellConfig = {
  id: number
  value: any
}

// TODO review 下，之前写的有点看不懂了。。
export const PortalProvider: FC<{
  domEl: HTMLElement
}> = ({ children, domEl }) => {
  const elementIdRef = useRef(0)
  const [cells, setCells] = useState<CellConfig[]>([])

  const createCell = useCallback(() => {
    const id = elementIdRef.current++
    const ref = {
      current: {
        setChildren: (el: any) => {},
      },
    }

    setTimeout(() => {
      setCells((e) => {
        return [
          ...e,
          {
            id,
            value: <Cell ref={ref} />,
          },
        ]
      })
    })

    return {
      render: (value: any) => {
        setTimeout(() => {
          ref.current?.setChildren(value)
        })
      },
      destroy: () => {
        setCells((e) => e.filter((i) => i.id !== id))
      },
    }
  }, [])

  return (
    <PortalCtx.Provider
      value={{
        createCell,
      }}
    >
      {createPortal(
        <>
          {cells.map((e) => {
            return <Fragment key={e.id}>{e.value}</Fragment>
          })}
        </>,
        domEl,
      )}
      {children}
    </PortalCtx.Provider>
  )
}

export const usePortal = (node: any) => {
  const { createCell } = useContext(PortalCtx)

  const { render, destroy } = useMemo(() => {
    return createCell()
  }, [createCell])

  useEffect(() => {
    return destroy
  }, [destroy])

  render(node)
}
