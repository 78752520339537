import React, { useState, useEffect, Suspense } from 'react'
import { VerticalEditorPanels } from './VerticalEditorPanels'
import { IDENavBar } from './IDENavBar'
import { SlateEditor } from '../../editor/SlateEditor'
import { MonacoEditor, MonacoDiffEditor } from './MonacoEditor'
import { useCallback } from 'react'
import { useRepoParams } from '../../../hooks/useRepoQuery'
import {
  createDraftLegacy,
  getDraftLegacy,
  updateDraftLegacy,
  deleteDraftLegacy,
} from '../../../services/docs'
import { useGitHubRepo, useGitHubRawContent } from '../../../services/github'
import { useHistory } from 'react-router-dom'
import { getDraftUrlLegacy, getRepoUrl } from '../../../helpers/urls'
import { Loading, Button, Markdown, LoadingScreen } from 'lib-react-components'
import { SyncV1, useWindowSize, useSetDocumentTitle } from 'lib-react-hooks'
import { DraftLegacy } from '../../../types/types'
import { useRef } from 'react'
import { computeDraftSha } from '../../../helpers/draft'
import { useMemo } from 'react'
import style from './IDE.module.scss'
import { IconFile, IconGit, IconPicture, IconMeta, IconEye } from '../../_shared/Icon'
import { CommitEditorLegacy } from './CommitEditorLegacy'
import { draftToBlobContent } from '../../../helpers/markdown'
import { DraftListLegacy } from './DraftListLegacy'
import { DraftMetaEditor } from '../../editor/DraftMetaEditor'
import { pick } from 'lodash'
import { useQuery } from '../../../hooks/useQuery'
import classNames from 'classnames'
import { useTheme } from '../../../services/theme'
import { css } from 'emotion'
import { Hint } from '../../_shared/Hint'
import { usePreference } from '../../preference/usePreference'

const { useSync, SyncError } = SyncV1

const panelsMap = {
  meta: {
    label: 'Meta',
    key: 'panel_meta',
    icon: (
      <IconMeta
        style={{
          fontSize: 22,
        }}
      />
    ),
  },
  files: {
    label: 'Files',
    key: 'panel_files',
    icon: (
      <IconFile
        style={{
          fontSize: 20,
        }}
      />
    ),
  },
  assets: {
    label: 'Assets',
    key: 'panel_assets',
    icon: (
      <IconPicture
        style={{
          fontSize: 22,
        }}
      />
    ),
  },
  commit: {
    label: 'Commit',
    key: 'panel_commit',
    icon: (
      <IconGit
        style={{
          fontSize: 26,
        }}
      />
    ),
  },
}
const panels = Object.values(panelsMap)

export const IDE: React.FC<{}> = () => {
  const { height, width } = useWindowSize()
  const history = useHistory()
  const { repo, owner } = useRepoParams()
  let { draftId } = useQuery<{
    draftId?: string
  }>()
  const theme = useTheme()
  const {
    preference: { editor },
  } = usePreference()

  if (draftId === 'new' || draftId === undefined) {
    draftId = null
  }

  useSetDocumentTitle(draftId === undefined ? 'IDE' : draftId ? 'Edit draft' : 'Create draft')

  // data hooks
  const { data: repoInfo } = useGitHubRepo(owner, repo, {
    suspense: false,
  })

  const fetchRemote = useCallback(async () => {
    if (draftId) {
      return getDraftLegacy(draftId).catch((err) => {
        if (err.statusCode === 404) {
          return Promise.reject({
            code: SyncError.REMOTE_ITEM_NOT_FOUND,
          })
        }
        throw err
      })
    }
    return null
  }, [draftId])

  const updateRemote = useCallback(
    (localState: DraftLegacy) => {
      if (draftId) {
        return updateDraftLegacy(draftId, localState)
      }

      return createDraftLegacy({
        ...localState,
        ownerId: repoInfo.owner.id,
        repoId: repoInfo.id,
      }).then((draft) => {
        const draftUrl = getDraftUrlLegacy(owner, repo, draft._id)
        history.replace(draftUrl)
        return draft
      })
    },
    [draftId, history, owner, repo, repoInfo],
  )

  const handleConflict = useCallback(async (localState) => {
    return new Promise<Partial<DraftLegacy>>((resolve) => {
      resolveConflictRef.current = (data) => {
        resolve(data)
        resolveConflictRef.current = null
      }
    })
  }, [])

  // states
  const [panel, setPanel] = useState('panel_meta')
  const [asideCollapsed, setAsideCollapsed] = useState(false)
  const [currentDraftDeleted, setCurrentDraftDeleted] = useState(false)
  const [showPreview, setShowPreview] = useState(false)

  // refs
  const resolveConflictRef = useRef<(draft: Partial<DraftLegacy>) => void>(null)
  const editorWrapperRef = useRef(null)

  // misc
  const newDraft = useMemo<Partial<DraftLegacy>>(() => {
    return {
      content: '',
      path: null,
      tags: [],
      date: new Date(),
      updated: new Date(),
    }
  }, [])
  const currentPanelConfig = useMemo(() => {
    return panels.find((p) => p.key === panel)
  }, [panel])
  const finalPanels = useMemo(() => {
    if (draftId === null) {
      return [panelsMap.meta, panelsMap.files]
    }
    return [panelsMap.meta, panelsMap.files, panelsMap.commit]
  }, [draftId])

  const editorHeight = height - 40
  let editorWidth = width - 50
  if (!asideCollapsed) {
    editorWidth -= 300
  }
  if (showPreview) {
    editorWidth /= 2
  }

  const {
    localState,
    updateLocalState,
    isPulling,
    isInSync,
    isPushing,
    error,
    isPullingInitial,
    conflict,
    remoteState,
  } = useSync<DraftLegacy>({
    fetchRemote,
    updateRemote,
    initialLocalState: newDraft,
    handleConflict,
    computeSha: computeDraftSha,
  })

  const idle = !isPulling && !isPushing
  // const draftTitle = localState === newDraft ? null : extractTitleFromContent(localState.content)

  const mdWithMeta = useMemo(() => {
    return draftToBlobContent(localState)
  }, [localState])

  const {
    data: prevBlob,
    isValidating: isLoadingPrevBlob,
    revalidate: revalidatePrevBlob,
  } = useGitHubRawContent(owner, repo, localState.path)

  const currentDraftDisabled = currentDraftDeleted || Boolean(error)
  const Editor = conflict ? MonacoDiffEditor : editor === 'monaco' ? MonacoEditor : SlateEditor

  useEffect(() => {
    setCurrentDraftDeleted(false)
  }, [draftId])

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <IDENavBar
        owner={owner}
        repo={repo}
        backPath={getRepoUrl(owner, repo)}
        leftSlot={
          <div
            style={{
              color: '#fff',
              display: 'flex',
              flexDirection: 'row',
              marginLeft: 10,
            }}
            className="y-center"
          >
            {conflict && (
              <div
                className={css`
                  color: orange;
                  margin-right: 10px;
                `}
              >
                Conflict
              </div>
            )}
            {conflict && (
              <div
                className={css`
                  margin-right: 10px;
                `}
              >
                <Button
                  size="mini"
                  preset="primary"
                  onClick={() => {
                    resolveConflictRef.current(localState)
                  }}
                >
                  Mark as resolved
                </Button>
                <Button
                  size="mini"
                  preset="primary"
                  onClick={() => {
                    resolveConflictRef.current(remoteState)
                  }}
                >
                  Use remote
                </Button>
              </div>
            )}
            {(isPulling || isPushing) && <Loading size="small" />}
            {!isInSync && idle && (
              <div
                style={{
                  marginRight: 5,
                }}
              >
                Not in sync
              </div>
            )}
            {error && (
              <div
                style={{
                  color: 'red',
                }}
              >
                {error.message}
              </div>
            )}
          </div>
        }
        rightSlot={
          <IconEye
            onClick={() => {
              setShowPreview((flag) => !flag)
            }}
            style={{
              marginRight: 10,
              position: 'relative',
              top: 2,
              cursor: 'pointer',
              fontSize: 20,
              color: showPreview ? theme.linkColor : theme.textColor,
            }}
          />
        }
      />
      <div
        style={{
          display: 'flex',
          height: 'calc(100% - 40px)',
        }}
      >
        <VerticalEditorPanels
          panels={finalPanels}
          value={panel}
          onChange={(e) => {
            if (e === panel) {
              setAsideCollapsed((v) => !v)
            } else {
              setAsideCollapsed(false)
            }
            setPanel(e)
          }}
        />
        {!asideCollapsed && (
          <div className={style.aside}>
            <div className={style.panelTitle}>{currentPanelConfig.label}</div>
            <div className={classNames(style.asideContent, 'scrollable')}>
              {panel === 'panel_files' && (
                <DraftListLegacy
                  active={draftId}
                  onItemClick={(draft) => {
                    history.push(getDraftUrlLegacy(owner, repo, draft._id))
                  }}
                  onItemDeleted={(draft) => {
                    if (draft._id === draftId) {
                      setCurrentDraftDeleted(true)
                    }
                  }}
                />
              )}
              {panel === 'panel_meta' && (
                <DraftMetaEditor
                  disabled={currentDraftDisabled}
                  value={pick(localState, ['path', 'tags'])}
                  onChange={updateLocalState}
                />
              )}
              {panel === 'panel_commit' && (
                <Suspense fallback={<LoadingScreen />}>
                  <CommitEditorLegacy
                    disabled={currentDraftDisabled}
                    repo={repo}
                    owner={owner}
                    content={mdWithMeta}
                    path={localState.path}
                    prevBlob={prevBlob}
                    onCommitSuccess={() => {
                      revalidatePrevBlob()
                      deleteDraftLegacy(draftId).then(() => {
                        setCurrentDraftDeleted(true)
                      })
                    }}
                    branch={repoInfo.default_branch}
                  />
                </Suspense>
              )}
            </div>
          </div>
        )}
        <div
          style={{
            flex: '1',
          }}
          ref={editorWrapperRef}
        >
          {panel !== 'panel_commit' && (
            <Loading isLoading={isPullingInitial}>
              {currentDraftDeleted ? (
                <Hint>Draft deleted</Hint>
              ) : error?.code === SyncError.REMOTE_ITEM_NOT_FOUND ? (
                <Hint>Draft not found</Hint>
              ) : (
                <div className="space-between">
                  <Editor
                    height={editorHeight}
                    width={editorWidth}
                    language="markdown"
                    value={localState.content}
                    original={remoteState?.content}
                    theme={theme.dark ? 'vs-dark' : 'vs'}
                    onChange={(md) => {
                      updateLocalState({
                        content: md,
                        updated: new Date(),
                      })
                    }}
                    options={{
                      wordWrap: 'on',
                      wordBasedSuggestions: false,
                      renderSideBySide: false,
                      minimap: {
                        enabled: false,
                      },
                      lineNumbers: 'off',
                    }}
                  />
                  <div
                    style={{
                      height: editorHeight,
                      width: editorWidth,
                      overflowY: 'auto',
                      padding: '0 20px',
                    }}
                  >
                    <Markdown markdown={localState.content} />
                  </div>
                </div>
              )}
            </Loading>
          )}
          {panel === 'panel_commit' &&
            (isLoadingPrevBlob ? (
              <Loading />
            ) : prevBlob ? (
              <MonacoDiffEditor
                width="100%"
                height={editorHeight}
                original={prevBlob.content}
                language="markdown"
                value={mdWithMeta}
                options={{
                  readOnly: true,
                  wordWrap: 'on', // 没生效
                }}
              />
            ) : (
              <Hint>Nothing to diff</Hint>
            ))}
        </div>
      </div>
    </div>
  )
}

export default IDE
