import { Fallback, Loading, renderFallback } from 'lib-react-components'
import React from 'react'
import { useUser } from '../../services/auth'

export const AuthGuard: React.FC<{
  fallback?: Fallback
  loading?: any
  noCheck?: boolean
}> = (props) => {
  const { data, isValidating, error } = useUser({
    suspense: false,
  })

  if (props.noCheck) {
    return <>{props.children}</>
  }

  if (error) {
    if (props.fallback !== undefined) {
      return renderFallback(props.fallback, error)
    }
    throw error
  }

  if ((!data && isValidating) || (!data && !error)) {
    return props.loading
  }

  return <>{props.children}</>
}

AuthGuard.defaultProps = {
  loading: <Loading />,
}
