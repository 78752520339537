import React from 'react'
import { Link } from 'react-router-dom'
import { GitHub } from '../../types/github'

export const CommitLogs: React.FC<{
  commits: GitHub.CommitItem[]
  active?: string
  onCommitClick?: (
    data: GitHub.CommitItem,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => void
  getLinkUrl?: (data: GitHub.CommitItem) => string
}> = (props) => {
  const { commits, active, onCommitClick, getLinkUrl } = props

  return (
    <div>
      {commits.map((commit) => {
        const isCurrent = active === commit.sha

        return (
          <div key={commit.sha}>
            <Link
              style={{
                fontWeight: isCurrent ? 'bold' : 'normal',
              }}
              to={getLinkUrl?.(commit) || '#'}
              onClick={(e) => {
                onCommitClick?.(commit, e)
              }}
            >
              commit {commit.sha}
            </Link>
            <div>{commit.commit.message}</div>
            <br />
          </div>
        )
      })}
    </div>
  )
}
