import { Dropdown, Menu } from 'antd'
import { DropDownProps } from 'antd/lib/dropdown'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'
import { css } from 'emotion'
import React from 'react'
import { useIsMobile } from '../../hooks/useIsMobile'
import { IconDots } from './Icon'

export const BasicDropdown: React.FC<
  {
    items: (
      | (Omit<MenuItemProps, 'children'> & {
          content: any
        })
      | React.ReactElement
    )[]
  } & Omit<DropDownProps, 'overlay'>
> = (props) => {
  const { items, ...rest } = props
  const mobileFlag = useIsMobile()

  return (
    <Dropdown
      {...rest}
      trigger={[mobileFlag ? 'click' : 'hover']}
      overlay={
        <Menu
          style={{
            minWidth: 130,
          }}
        >
          {items.filter(Boolean).map((item, idx) => {
            if (React.isValidElement(item)) {
              return React.cloneElement(item, { key: idx })
            }
            const { content, ...rest } = item
            return (
              <Menu.Item {...rest} key={idx}>
                {content}
              </Menu.Item>
            )
          })}
        </Menu>
      }
    >
      <IconDots
        className={css`
          font-size: 15px;
          padding: 10px 5px;
          cursor: pointer;
        `}
      />
    </Dropdown>
  )
}
