// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details
import { Button } from 'lib-react-components'
import React, { HTMLAttributes, useState } from 'react'
import { css, cx } from 'emotion'
import { IconArrowDown2 } from './Icon'

export const Details: React.FC<{
  defaultOpen?: boolean
  summary?: any
  right?: any
} & HTMLAttributes<HTMLDivElement>> = (props) => {
  const { defaultOpen, summary, children, right, ...rest } = props
  const [open, setOpen] = useState(defaultOpen)

  if (!children) {
    return null
  }

  const renderIcon = (_open: boolean) => {
    return (
      <IconArrowDown2
        className={css`
          font-size: 14px;
          transform: rotate(${_open ? 0 : -90}deg);
          margin-right: 5px;
        `}
      />
    )
  }

  return (
    <div {...rest} data-component="Details">
      <div
        className={cx(
          css`
            cursor: pointer;
            user-select: none;
          `,
          'space-between',
        )}
        onClick={() => {
          setOpen((f) => !f)
        }}
      >
        <Button
          style={{
            border: 'none',
          }}
          linkLike
        >
          <div>
            {renderIcon(open)}
            {summary}
          </div>
        </Button>
        {right && <div className="y-center">{right}</div>}
      </div>
      {open && <div>{children}</div>}
    </div>
  )
}

Details.defaultProps = {
  defaultOpen: false,
  summary: 'Details',
}
