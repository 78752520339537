import React, { useEffect } from 'react'
import { Article } from '../_shared/Article'
import { MainContent } from '../_shared/MainContent'
import { SiteHeader } from '../_core/SiteHeader'
import { useSetDocumentTitle } from 'lib-react-hooks'
import { useSetLastUsed } from '../../hooks/useSetLastUsed'
import { useArticle } from '../../services/docs'
import { RouterSearch } from '../_shared/RouterSearch'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { useIsMobile } from '../../hooks/useIsMobile'

const ArticleViewerHeaderLeft: React.FC<{}> = () => {
  const { owner, repo, blobRef } = useRepoParams()
  const { data: article } = useArticle({ owner, repo, blobRef })
  const mobileFlag = useIsMobile()

  return !mobileFlag && <RouterSearch _ref={article.ref} />
}

const ArticleViewerContent: React.FC<{}> = () => {
  const { owner, repo, blobRef } = useRepoParams()
  const { data: article } = useArticle({ owner, repo, blobRef })
  useSetDocumentTitle(article.title)

  useEffect(() => {
    document.scrollingElement.scrollTop = 0
  }, [article._id])

  return <Article article={article} />
}

export const ArticleViewer: React.FC<{}> = () => {
  useSetLastUsed()

  return (
    <>
      <SiteHeader left={<ArticleViewerHeaderLeft />} />
      <MainContent>
        <ArticleViewerContent />
      </MainContent>
    </>
  )
}
