import React from 'react'
import { Avatar } from 'lib-react-components'
import { MainContent } from '../_shared/MainContent'
import { SiteHeader } from '../_core/SiteHeader'
import { css } from 'emotion'
import { useGitHubUser } from '../../services/github'
import { useTheme } from '../../services/theme'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useSetDocumentTitle } from 'lib-react-hooks'

const ProfileContent: React.FC<{}> = () => {
  const { data: user } = useGitHubUser()
  const theme = useTheme()
  const mobileFlag = useIsMobile()

  useSetDocumentTitle('Profile')

  return (
    <div
      className={css`
        text-align: center;
        margin-top: ${mobileFlag ? 80 : 150}px;
      `}
    >
      <Avatar round url={user.avatar_url} name={user.name} size={220} />
      <div>
        <div
          className={css`
            font-size: 39px;
            margin-top: 30px;
          `}
        >
          {user.name}
        </div>
        <div
          className={css`
            font-size: 22px;
            color: #1fb81f;
          `}
        >
          /* {user.bio} */
        </div>
        <div
          className={css`
            margin-top: 10px;
            color: ${theme.textColorLight};
            & a {
              color: ${theme.textColorLight};
              &:hover {
                color: ${theme.linkColorHover};
              }
            }
          `}
        >
          <div>
            <a target="_blank" rel="noopener noreferrer" href={user.html_url}>
              {user.html_url}
            </a>
          </div>
          <span
            role="img"
            aria-label="love"
            className={css`
              margin-top: 20px;
              display: inline-block;
            `}
          >
            ❤️
          </span>
        </div>
      </div>
    </div>
  )
}

export const Profile: React.FC = () => {
  return (
    <>
      <SiteHeader />
      <MainContent>
        <ProfileContent />
      </MainContent>
    </>
  )
}

export default Profile
