import React from 'react'
import { useGitHubRepo } from '../../../services/github'
import { useRepoParams } from '../../../hooks/useRepoQuery'
import { useListDraftsLegacy, deleteDraftLegacy } from '../../../services/docs'
import { NoResult } from '../../_shared/NoResult'
import { DraftItemLegacy } from '../DraftItemLegacy'
import { DraftLegacy } from '../../../types/types'

/**
 * @deprecated
 */
export const DraftListLegacy: React.FC<{
  active: string
  onItemClick?: (item: DraftLegacy) => void
  onItemDeleted?: (item: DraftLegacy) => void
}> = (props) => {
  const { onItemClick, active, onItemDeleted } = props
  const query = useRepoParams()
  const { repo, owner } = query

  // data hooks
  const { data: repoInfo } = useGitHubRepo(owner, repo)
  const { data: drafts, revalidate } = useListDraftsLegacy({
    repoId: repoInfo.id,
    ownerId: repoInfo.owner.id,
  })

  const isEmpty = drafts.length === 0

  return (
    <div>
      {isEmpty && <NoResult title="没有草稿" />}
      {drafts.map((draft) => {
        return (
          <DraftItemLegacy
            active={draft._id === active}
            onClick={() => {
              onItemClick(draft)
            }}
            draft={draft}
            key={draft._id}
            onDelete={() => {
              deleteDraftLegacy(draft._id).then(() => {
                revalidate()
                onItemDeleted?.(draft)
              })
            }}
          />
        )
      })}
    </div>
  )
}
