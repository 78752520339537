import md5 from 'md5'
import { DraftLegacy } from '../types/types'

export const computeDraftSha = (draft: DraftLegacy) => {
  const str = !draft
    ? draft + ''
    : JSON.stringify({
        content: draft.content,
        tags: draft.tags.sort(),
        path: draft.path,
        date: draft.date.valueOf(),
        updated: draft.updated.valueOf(),
      })

  return md5(str)
}
