import { useRepoParams } from './useRepoQuery'
import { useEffect } from 'react'
import { updatePreference } from '../components/preference/service'

export const useSetLastUsed = () => {
  const { owner, repo } = useRepoParams()

  useEffect(() => {
    updatePreference({
      lastUsed: {
        owner,
        repo,
      },
    })
  }, [owner, repo])
}
