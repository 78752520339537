import { css } from 'emotion'
import { Button } from 'lib-react-components'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useIsMobile } from '../../hooks/useIsMobile'
import { usePortal } from '../../hooks/usePortal'
import { useURLHelper } from '../../hooks/useURLHelper'
import { IconFile } from '../_shared/Icon'

export const CreateDraftButton: React.FC<{}> = (props) => {
  const history = useHistory()
  const url = useURLHelper()
  const mobileFlag = useIsMobile()

  const ele = (
    <Button
      round
      preset="primary"
      onClick={() => {
        history.push(url.getEditorUrl('new'))
      }}
      className={
        mobileFlag &&
        css`
          position: fixed;
          right: 20px;
          bottom: 50px;
        `
      }
    >
      <IconFile
        style={{
          marginRight: 5,
        }}
      />
      创建
    </Button>
  )

  usePortal(mobileFlag && ele)

  return !mobileFlag && ele
}
