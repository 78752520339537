import { useGitHead } from '../services/git'
import { useGitHubCommits } from '../services/github'
import { findItemIndex } from '../utils'

export const useCommitOffsetCount = (owner: string, repo: string) => {
  const { data: head } = useGitHead(owner, repo)
  const { data: commits } = useGitHubCommits(owner, repo, head.current)

  return findItemIndex(commits, (c) => c.sha === head.sha)
}
