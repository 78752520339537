import useSWR, { ConfigInterface, useSWRInfinite } from 'swr-fork'
import { api } from '../helpers/network'
import { DraftLegacy } from '../types/types'
import qs from 'qs'
import { DEFAULT_SORT } from '../config'
import { useEnhancedSWRPages } from '../hooks/useEnhancedSWRPages'
import { DataList } from '../types/common'
import { Service } from '../types/service'

// drafts (legacy) /////////////////////////////////////////////////////////////////////////////////

const processDraft = (data: DraftLegacy) => {
  return {
    ...data,
    date: new Date(data.date),
    updated: new Date(data.updated),
  }
}

/**
 * @deprecated
 */
export const useListDraftsLegacy = (config: { ownerId: number; repoId: number }) => {
  return useSWR<
    (DraftLegacy & {
      excerpt: string
    })[]
  >(`docs/drafts-legacy?${qs.stringify(config)}`, {
    suspense: true,
  })
}

/**
 * @deprecated
 */
export const createDraftLegacy = (
  draft: Pick<DraftLegacy, 'content' | 'repoId' | 'ownerId' | 'path'>,
) => {
  return api.post<DraftLegacy>(`docs/drafts-legacy`, draft).then(processDraft)
}

/**
 * @deprecated
 */
export const updateDraftLegacy = (id: string, draft: Partial<DraftLegacy>) => {
  return api.put<DraftLegacy>(`docs/drafts-legacy/${id}`, draft).then(processDraft)
}

/**
 * @deprecated
 */
export const deleteDraftLegacy = (id: string) => {
  return api.delete(`docs/drafts-legacy/${id}`)
}

/**
 * @deprecated
 */
export const getDraftLegacy = (id: string) => {
  return api.get<DraftLegacy>(`docs/drafts-legacy/${id}`).then(processDraft)
}

// recent-repos ////////////////////////////////////////////////////////////////////////////////////

export const useRecentRepos = (swrConfig: ConfigInterface = {}) => {
  return useSWR<Service.RecentRepo[]>(`docs/recent-repos`, {
    suspense: true,
    ...swrConfig,
  })
}

export const markRepoViewed = (repoId: number) => {
  return api.put(`docs/recent-repos/${repoId}`)
}

// drafts //////////////////////////////////////////////////////////////////////////////////////////

export const createDraft = (config: {
  owner: string
  repo: string
  path?: string
  content?: string
  date?: Date
  updated?: Date
  tags?: string[]
}) => {
  const { owner, repo, ...draft } = config
  return api.post<Service.Draft>(`docs/drafts`, draft, {
    params: {
      owner,
      repo,
    },
  })
}

export const updateDraft = (config: {
  _id: string
  owner: string
  repo: string
  path?: string
  content?: string
  date?: Date
  updated?: Date
  tags?: string[]
  sha: string
}) => {
  const { owner, repo, _id, ...draft } = config
  return api.put<Service.Draft>(`docs/drafts/${_id}`, draft, {
    params: {
      owner,
      repo,
    },
  })
}

export const getDraft = (config: { owner: string; repo: string; slug: string }) => {
  const { owner, repo, slug } = config
  return api.get<Service.Draft>(`docs/drafts/${slug}`, {
    params: {
      owner,
      repo,
    },
  })
}

export const deleteDraft = (config: { owner: string; repo: string; slug: string }) => {
  const { owner, repo, slug } = config
  return api.delete(`docs/drafts/${slug}`, {
    params: {
      owner,
      repo,
    },
  })
}

export const useDraft = (params: {
  owner: string
  repo: string
  path?: string
  disabled?: boolean
  fallback?: unknown
  suspense?: boolean
}) => {
  const { owner, repo, path, disabled, fallback, suspense = true } = params
  const url = `docs/drafts/${path}?${qs.stringify({
    owner,
    repo,
  })}`

  return useSWR<Service.Draft>(
    !disabled && url,
    (url) => {
      return api.get(url).catch((err) => {
        if (fallback !== undefined) {
          return fallback
        }
        throw err
      })
    },
    {
      suspense,
    },
  )
}

export type ListDraftQuery = Omit<ListArticleQuery, 'ref'>

export const useDraftPages = (config: ListDraftQuery) => {
  const { owner, repo, ...restQuery } = config
  const swr = useSWRInfinite<DataList<Service.Draft>>(
    (n, prev) => {
      if (prev?.next === null) {
        return null
      }
      return `docs/drafts?${qs.stringify({
        sort: DEFAULT_SORT,
        ...restQuery,
        owner,
        repo,
        page: prev?.next || 1,
      })}`
    },
    {
      // 没有 refetch 的必要 commit 不变 blob 就不变
      revalidateOnFocus: false,
      revalidateAll: true,
    },
  )
  return useEnhancedSWRPages(swr)
}

// articles ////////////////////////////////////////////////////////////////////////////////////////

export const useListArticles = (query: ListArticleQuery, swrConfig: ConfigInterface = {}) => {
  return useSWR<DataList<Service.Article>>(
    `docs/articles?${qs.stringify({
      sort: DEFAULT_SORT,
      ...query,
    })}`,
    {
      suspense: true,
      revalidateOnFocus: false,
      ...swrConfig,
    },
  )
}

export type ListArticleQuery = {
  ref: string
  owner: string
  repo: string
  page?: number
  limit?: number
  sort?: string // key-<desc|asc>
  search?: string
  tag?: string
}

export const useArticlePages = (config: ListArticleQuery) => {
  const { owner, repo, ref, ...restQuery } = config
  const swr = useSWRInfinite<DataList<Service.Article>>(
    (n, prev) => {
      if (prev?.next === null) {
        return null
      }
      return `docs/articles?${qs.stringify({
        sort: DEFAULT_SORT,
        ...restQuery,
        owner,
        repo,
        page: prev?.next || 1,
        ref,
      })}`
    },
    {
      // 没有 refetch 的必要 commit 不变 blob 就不变
      revalidateOnFocus: false,
    },
  )
  return useEnhancedSWRPages(swr)
}

export const useArticle = (params: {
  owner: string
  repo: string
  blobRef?: string
  disabled?: boolean
  fallback?: unknown
  suspense?: boolean
}) => {
  const { owner, repo, blobRef, disabled, fallback, suspense = true } = params
  const url = `docs/articles/${blobRef}?${qs.stringify({
    owner,
    repo,
  })}`

  return useSWR<Service.Article>(
    !disabled && url,
    (url) =>
      api.get(url).catch((err) => {
        if (fallback !== undefined) {
          return fallback
        }
        throw err
      }),
    {
      suspense,
    },
  )
}
