import { css, cx } from 'emotion'
import React, { HTMLAttributes } from 'react'
import { useTheme } from '../../services/theme'

export const StatusBar: React.FC<{} & HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, className, ...rest } = props
  const theme = useTheme()

  return (
    <div
      {...rest}
      className={cx(
        css`
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 5px 5px;
          border-top: 1px solid ${theme.borderColor};
          background-color: #fff;
          font-size: 12px;
          height: ${theme.statusBarHeight}px;
        `,
        className,
      )}
    >
      {children}
    </div>
  )
}
