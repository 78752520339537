import { createRequest, delay } from 'lib-core'
import { message } from 'antd'
import { cache as swrCache } from 'swr-fork'
import { processResponse } from './data'

const getUserCache = () => {
  return swrCache.get('/auth/profile')
}

const GITHUB_BASE_URL = 'https://api.github.com'
const API_BASE_URL = '/api'

const isEnvDevelopment = process.env.NODE_ENV === 'development'

const getGitHubToken = () => {
  const user = getUserCache()
  return user?.github?.token
}

export const api = createRequest({
  baseURL: API_BASE_URL,
  withCredentials: true,
  postProcessData: processResponse,
})

export const swrFetcher = api

api.axios.interceptors.request.use((config) => {
  if (config.url.startsWith('/no-cache')) {
    config.url = config.url.substr('/no-cache'.length)
  }
  return config
})

api.axios.interceptors.response.use(async (res) => {
  if (isEnvDevelopment) {
    await delay(300 * Math.random())
    // await delay(500)
  }
  return res
})

export const github = createRequest({
  baseURL: GITHUB_BASE_URL,
  headers: {
    Accept:
      'application/vnd.github.mercy-preview+json, application/vnd.github.baptiste-preview+json',
    // 不行，过不了 cors
    // 'Cache-Control': 'max-age=0',
    // https://github.com/octokit/rest.js/issues/890#issuecomment-487937187
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/If-None-Match
    // 可以禁用缓存，没太明白为什么
    'If-None-Match': '',
  },
})

github.axios.interceptors.request.use((request) => {
  const token = getGitHubToken()
  if (token) {
    request.headers.Authorization = `token ${token}`
  }
  return request
})

github.axios.interceptors.response.use((res) => res)

export const notifyAPIError = (err: any) => {
  message.error(err?.message || 'Service error')
}
