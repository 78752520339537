import React, { useState } from 'react'
import { DraftLegacy } from '../../types/types'
import { Link } from 'react-router-dom'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { getDraftUrlLegacy } from '../../helpers/urls'
import { formatDate } from '../../utils'
import { Button } from 'lib-react-components'
import { Modal } from 'antd'

/**
 * @deprecated
 */
export const DraftCardLegacy: React.FC<{
  draft: DraftLegacy & {
    excerpt: string
  }
  onDelete: () => void
}> = (props) => {
  const {
    draft: { date, title, _id, excerpt, updated },
    onDelete,
  } = props

  const { owner, repo } = useRepoParams()
  const [showUpdatedAt, setShowUpdatedAt] = useState(true)
  const edited = updated && date.valueOf() !== updated.valueOf()

  const draftUrl = getDraftUrlLegacy(owner, repo, _id)

  return (
    <div
      style={{
        marginBottom: 30,
      }}
    >
      <Link to={draftUrl}>
        <h1
          style={{
            margin: 0,
            marginBottom: 5,
            color: '#555',
          }}
        >
          {title || 'Untitled'}
        </h1>
      </Link>
      <div
        style={{
          fontSize: 18,
          color: '#666',
        }}
      >
        {excerpt}
      </div>
      <div
        onClick={() => {
          setShowUpdatedAt((v) => !v)
        }}
        style={{
          ...(edited && {
            cursor: 'pointer',
          }),
          display: 'inline-block',
          fontSize: 16,
          color: '#999',
          userSelect: 'none',
        }}
      >
        {(!showUpdatedAt || !edited) && date && (
          <>
            <span>创建于 {formatDate(date)}</span>
          </>
        )}
        {edited && showUpdatedAt && updated && (
          <>
            <span>编辑于 {formatDate(updated)}</span>
          </>
        )}
      </div>
      <div className="sep">|</div>
      <Button
        linkLike
        onClick={() => {
          Modal.confirm({
            content: `确定删除 ${title}?`,
            onOk: onDelete,
          })
        }}
      >
        删除
      </Button>
    </div>
  )
}
