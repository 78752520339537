import React, { Suspense } from 'react'
import { AutoUpdater, OAuth, LoadingScreen, ThemeRoot } from 'lib-react-components'
import { RecoilRoot } from 'recoil'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import { SWRConfig } from 'swr-fork'
import { swrFetcher } from './helpers/network'
import { ErrorBoundary } from './components/_core/ErrorBoundary'
import { retrieveGithubOAuthResult } from './services/auth'
import { PortalProvider } from './hooks/usePortal'
import { Router } from './components/_core/Router'
import { ENABLE_SERVICE_WORKER } from './config'
import { routes } from './routes'
import { Cache } from './components/_core/Cache'
import { enhancedThemeConfig$, getEnhancedTheme, getEnhancedThemeConfig } from './services/theme'
import { DocumentTitleConfigProvider } from 'lib-react-hooks'

const isEnvDevelopment = process.env.NODE_ENV === 'development'

const formatTitle = (base: string, title: string) => {
  return `${isEnvDevelopment ? '[DEV] ' : ''}${base} - ${title}`
}

const portalEl = document.getElementById('portal')

export const Root = () => {
  return (
    <ThemeRoot
      config$={enhancedThemeConfig$}
      getInitialConfig={getEnhancedThemeConfig}
      getTheme={getEnhancedTheme}
    >
      <ConfigProvider
        locale={zhCN}
        // 对 antd 的弹框不生效，无语
        autoInsertSpaceInButton={false}
      >
        <Suspense fallback={<LoadingScreen />}>
          <ErrorBoundary details="来自: Root">
            <Cache>
              <RecoilRoot>
                <DocumentTitleConfigProvider base="gitdocs" format={formatTitle}>
                  <OAuth retrieveOAuthResult={retrieveGithubOAuthResult}>
                    <AutoUpdater disabled={!ENABLE_SERVICE_WORKER} />
                    <SWRConfig
                      value={{
                        fetcher: swrFetcher,
                      }}
                    >
                      <PortalProvider domEl={portalEl}>
                        <Router routes={routes} />
                      </PortalProvider>
                    </SWRConfig>
                  </OAuth>
                </DocumentTitleConfigProvider>
              </RecoilRoot>
            </Cache>
          </ErrorBoundary>
        </Suspense>
      </ConfigProvider>
    </ThemeRoot>
  )
}
