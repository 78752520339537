import React, { Suspense, useEffect } from 'react'
import { Article } from '../_shared/Article'
import { useHistory } from 'react-router-dom'
import { MainContent } from '../_shared/MainContent'
import { SiteHeader } from '../_core/SiteHeader'
import { LoadingScreen } from 'lib-react-components'
import { useSetLastUsed } from '../../hooks/useSetLastUsed'
import { deleteDraft, useDraft } from '../../services/docs'
import { RouterSearch } from '../_shared/RouterSearch'
import { useURLHelper } from '../../hooks/useURLHelper'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { BasicDropdown } from '../_shared/BasicDropdown'
import { css, cx } from 'emotion'
import { handleServiceError } from '../../helpers/error'
import { atom, useRecoilValue, useRecoilState } from 'recoil'
import { Service } from '../../types/service'
import { Modal } from 'antd'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useSetDocumentTitle } from 'lib-react-hooks'

const ArticleDropdown: React.FC<{
  onEdit?: () => void
  onDelete?: () => Promise<void>
}> = (props) => {
  const { onEdit, onDelete } = props
  const draft = useRecoilValue(draftState)

  return (
    <BasicDropdown
      items={[
        onEdit && {
          content: '编辑',
          onClick: onEdit,
        },
        onDelete && {
          content: '删除',
          onClick: () => {
            Modal.confirm({
              content: `确定删除 "${draft.title}"？`,
              onOk: onDelete,
            })
          },
          danger: true,
        },
      ]}
    />
  )
}

const draftState = atom<Service.Draft>({
  key: 'draft',
  default: null,
})

const DraftContent: React.FC<{}> = () => {
  const { owner, repo, path } = useRepoParams()
  const { data: draft } = useDraft({ owner, repo, path })
  const [, setDraft] = useRecoilState(draftState)
  const history = useHistory()
  const { getEditorUrl } = useURLHelper()

  useSetDocumentTitle(draft.title)

  useEffect(() => {
    setDraft(draft)

    return () => {
      setDraft(null)
    }
  }, [draft, setDraft])

  return (
    <Article
      onDoubleClick={() => {
        history.replace(getEditorUrl(draft._id))
      }}
      article={draft}
    />
  )
}

export const DraftPage: React.FC<{}> = () => {
  const { owner, repo } = useRepoParams()
  const { getIndexUrl, getEditorUrl } = useURLHelper()
  const draft = useRecoilValue(draftState)
  const id = draft?.sha
  const history = useHistory()
  const mobileFlag = useIsMobile()

  useSetLastUsed()

  useEffect(() => {
    document.scrollingElement.scrollTop = 0
  }, [id])

  return (
    <>
      <SiteHeader
        left={!mobileFlag && <RouterSearch />}
        right={
          <div
            className={cx(
              css`
                margin-right: 10px;
              `,
              'y-center',
            )}
          >
            <ArticleDropdown
              onEdit={() => {
                history.replace(getEditorUrl(draft._id))
              }}
              onDelete={async () => {
                await deleteDraft({
                  owner,
                  repo,
                  slug: draft._id,
                })
                  .then(() => {
                    history.push(getIndexUrl())
                  })
                  .catch(handleServiceError)
              }}
            />
          </div>
        }
      />
      <MainContent>
        <Suspense fallback={<LoadingScreen text="加载草稿..." />}>
          <DraftContent />
        </Suspense>
      </MainContent>
    </>
  )
}

export default DraftPage
