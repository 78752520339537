import React, { useState } from 'react'
import {
  useGitStatus,
  useBlob,
  useGitHead,
  commit,
  useVirtualFile,
  ReferenceRecord,
  checkout,
} from '../../services/git'
import { Loading, LoadingScreen } from 'lib-react-components'
import { SiteHeader } from '../_core/SiteHeader'
import { MainContent } from '../_shared/MainContent'
import { css, cx } from 'emotion'
import MonacoEditor, { MonacoDiffEditor } from 'react-monaco-editor'
import { useTheme } from '../../services/theme'
import { Input, message, Modal } from 'antd'
import { Hint } from '../_shared/Hint'
import { Service } from '../../types/service'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { IconMinus, IconPlus, IconUndo } from '../_shared/Icon'
import { deleteDraft } from '../../services/docs'
import { Details } from '../_shared/Details'
import { Tag } from '../_shared/Tag'
import { toMap } from '../../utils'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useSetDocumentTitle, useWindowSize } from 'lib-react-hooks'

type ChangeType = 'added' | 'modified' | 'removed'

type ChangeDescriptor = Service.FileReference & {
  type: ChangeType
}

const colorMap = {
  added: 'green',
  modified: 'orange',
  removed: 'red',
}

const iconMap = {
  added: 'U',
  modified: 'M',
  removed: 'D',
}

const StatusIcon: React.FC<{
  type: ChangeType
}> = (props) => {
  const { type } = props
  const color = colorMap[type]
  const icon = iconMap[type]

  return (
    <div
      style={{
        color,
        marginRight: 5,
      }}
    >
      {icon}
    </div>
  )
}

const Change: React.FC<{
  change: ChangeDescriptor
  onClick?: () => void
  active?: boolean
  left?: any
  actions?: any
}> = (props) => {
  const {
    change: { path, type },
    onClick,
    active,
    left,
    actions,
  } = props

  return (
    <div
      className={cx(
        css`
          background-color: ${active ? '#ddd !important' : '#fff'};
          cursor: default;
          padding: 5px 10px;
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          &:hover {
            background-color: #eee;
          }

          & .change-actions {
            display: none;
          }
          &:hover {
            & .change-actions {
              display: block;
            }
          }
        `,
      )}
      onClick={onClick}
    >
      {left}
      <div
        style={{
          textDecoration: type === 'removed' ? 'line-through' : 'none',
        }}
        className={cx(
          css`
            flex: 1;
          `,
          'space-between',
        )}
      >
        {path}
      </div>
      {actions && <div className="change-actions">{actions}</div>}
      <StatusIcon type={type} />
    </div>
  )
}

const CommitEditorContent: React.FC<{}> = () => {
  const { owner, repo } = useRepoParams()
  const { data, revalidate } = useGitStatus(owner, repo)

  // states
  const [activeFile, setActiveFile] = useState(null)
  const [selectedPaths, setSelectedPaths] = useState<string[]>([])
  const [isCommitting, setIsCommitting] = useState(false)
  const [commitMsg, setCommitMsg] = useState('')
  const [commitError, setCommitError] = useState('')

  const theme = useTheme()
  const { width: winWidth, height: winHeight } = useWindowSize()
  const mobileFlag = useIsMobile()

  useSetDocumentTitle(`${owner}/${repo}`)

  const changes = Object.values(data.fileMap)
  const { data: headObject } = useGitHead(owner, repo)

  const { data: oldBlob, isValidating: isLoadingOld } = useBlob({
    owner,
    repo,
    blobRef: `${headObject.current}/${activeFile}`,
    disabled: !activeFile || !headObject,
    fallback: {},
    suspense: false,
  })

  const { data: newBlob, isValidating: isLoadingNew } = useVirtualFile({
    owner,
    repo,
    path: activeFile,
    disabled: !activeFile,
    fallback: {},
    suspense: false,
  })
  const isLoading = (isLoadingOld || isLoadingNew) && (!oldBlob || !newBlob)

  const currentChange = changes.find((c) => c.path === activeFile)
  const isTypeRemoved = currentChange?.type === 'removed'
  const Editor = isTypeRemoved ? MonacoEditor : MonacoDiffEditor

  const newText = newBlob?._text || ''
  const oldText = oldBlob?._text || ''

  const sidebarWidth = 400
  const editorWidth = winWidth - sidebarWidth
  const editorHeight = winHeight - theme.appBarHeight
  const changeMap = changes.reduce((map, change) => {
    return {
      ...map,
      [change.path]: change,
    }
  }, {} as Record<string, ChangeDescriptor>)

  const selectedChanges = selectedPaths.map((f) => changeMap[f])
  const unselectedChanges = changes.filter((c) => !selectedPaths.includes(c.path))

  const handleDiscard = async (changes: Service.ChangeDescriptor[]) => {
    const added = changes.filter((c) => c.type === 'added')
    const rest = changes.filter((c) => c.type !== 'added')

    await Promise.all(
      added
        .map((item) => deleteDraft({ owner, repo, slug: item.path }))
        .concat(rest.length && checkout({ owner, repo, paths: rest.map((r) => r.path) })),
    )

    const changesMap = toMap(changes, 'path')
    setSelectedPaths((paths) => {
      return paths.filter((p) => !changesMap[p])
    })

    if (changeMap[activeFile]) {
      setActiveFile(null)
    }

    return revalidate()
  }

  const detailStyle = css`
    & .details-action {
      display: none;
    }
    &:hover {
      & .details-action {
        display: block;
      }
    }
  `

  const handleCommit = () => {
    const records = changes
      .filter((c) => selectedPaths.includes(c.path))
      .map((c) => {
        if (c.type === 'removed') {
          return {
            ...c,
            type: 'remove',
          }
        }
        return {
          ...c,
          type: 'add',
        }
      }) as ReferenceRecord[]

    if (!records.length) {
      return
    }

    setIsCommitting(true)
    setCommitError('')

    commit({
      owner,
      repo,
      parentCommit: headObject.sha,
      branch: headObject.current,
      records,
      message: commitMsg,
    })
      .then((result) => {
        revalidate()
        message.success(`Committed to ${headObject.current} [${result.sha}]`)
        if (selectedPaths.includes(activeFile)) {
          setActiveFile(null)
        }
        setSelectedPaths([])
        setCommitMsg('')
        setCommitError('')
      })
      .catch((err) => {
        setCommitError(err.message)
      })
      .finally(() => {
        setIsCommitting(false)
      })
  }

  const renderUndo = (changes: ChangeDescriptor[]) => {
    return (
      <IconUndo
        className={css`
          font-size: 18px;
          cursor: pointer;
          margin-right: 10px;
        `}
        onClick={(e) => {
          e.stopPropagation()
          if (!changes.length) {
            return
          }

          if (changes.length === 1) {
            const change = changes[0]
            Modal.confirm({
              content:
                change.type === 'added'
                  ? `确定删除 "${change.path}" 吗？`
                  : `确定撤销对 "${change.path}" 的修改吗？`,
              onOk: () => handleDiscard([change]),
            })
            return
          }

          Modal.confirm({
            content: `确定撤销对 ${changes.length} 个文件的修改吗？`,
            onOk: () => handleDiscard(changes),
          })
        }}
      />
    )
  }

  const renderUnselect = (changes: ChangeDescriptor[]) => {
    return (
      <IconMinus
        onClick={(e) => {
          e.stopPropagation()
          setSelectedPaths(
            selectedPaths.filter((f) => {
              return !changes.map((c) => c.path).includes(f)
            }),
          )
        }}
        className={css`
          margin-right: 5px;
          cursor: pointer;
        `}
      />
    )
  }

  const renderSelect = (changes: ChangeDescriptor[]) => {
    return (
      <IconPlus
        onClick={(e) => {
          e.stopPropagation()
          setSelectedPaths(Array.from(new Set([...selectedPaths, ...changes.map((c) => c.path)])))
        }}
        className={css`
          margin-right: 5px;
          cursor: pointer;
        `}
      />
    )
  }

  const renderSidebar = () => {
    return (
      <div
        className={cx(
          mobileFlag
            ? css`
                width: 100%;
              `
            : css`
                width: ${sidebarWidth}px;
              `,
        )}
      >
        <Loading
          text="Committing..."
          isLoading={isCommitting}
          className={css`
            align-items: flex-start;
            & .ant-spin-nested-loading {
              width: 100%;
            }
          `}
        >
          <div
            style={{
              padding: 10,
            }}
          >
            <Input
              value={commitMsg}
              onChange={(e) => {
                setCommitMsg(e.target.value)
              }}
              placeholder={`Message (Cmd Enter to commit on ${headObject?.current})`}
              onKeyDown={(e) => {
                if (e.metaKey && e.key === 'Enter' && commitMsg) {
                  handleCommit()
                }
              }}
            />
            {commitError && (
              <div
                className={css`
                  color: red;
                  margin-top: 10px;
                `}
              >
                提交失败: {commitError}
              </div>
            )}
          </div>
          {!!selectedChanges.length && (
            <Details
              summary="选中的变更"
              defaultOpen
              className={css`
                margin-left: 5px;
                ${detailStyle}
              `}
              right={
                <>
                  <div className="details-action">
                    {renderUnselect(selectedChanges)}
                    {renderUndo(selectedChanges)}
                  </div>
                  <Tag>{selectedChanges.length}</Tag>
                </>
              }
            >
              {selectedChanges.map((change) => {
                const active = change.path === activeFile

                return (
                  <Change
                    key={change.path + change.type}
                    actions={
                      <>
                        {renderUnselect([change])}
                        {renderUndo([change])}
                      </>
                    }
                    active={active}
                    change={change}
                    onClick={() => {
                      setActiveFile(change.path)
                    }}
                  />
                )
              })}
            </Details>
          )}
          <Details
            summary="变更"
            defaultOpen
            className={css`
              margin-left: 5px;
              ${detailStyle}
            `}
            right={
              <>
                <div className="details-action">
                  {renderSelect(unselectedChanges)}
                  {renderUndo(unselectedChanges)}
                </div>
                <Tag>{unselectedChanges.length}</Tag>
              </>
            }
          >
            {unselectedChanges.map((change) => {
              const active = change.path === activeFile

              return (
                <Change
                  key={change.path + change.type}
                  actions={
                    <>
                      {renderSelect([change])}
                      {renderUndo([change])}
                    </>
                  }
                  active={active}
                  change={change}
                  onClick={() => {
                    setActiveFile(change.path)
                  }}
                />
              )
            })}
          </Details>
        </Loading>
      </div>
    )
  }

  return (
    <div
      className={css`
        display: flex;
        flex-direction: ${mobileFlag ? 'column' : 'row'};
      `}
    >
      {renderSidebar()}
      <div
        style={{
          flex: 1,
        }}
      >
        {isLoading ? (
          <div
            style={{
              margin: 20,
            }}
          >
            <LoadingScreen text={`加载 ${activeFile}...`} />
          </div>
        ) : activeFile ? (
          <Editor
            width={mobileFlag ? '100%' : editorWidth}
            value={isTypeRemoved ? oldText : newText}
            original={oldText}
            height={editorHeight}
            language="markdown"
            theme={theme.dark ? 'vs-dark' : 'vs'}
            options={{
              wordWrap: 'on',
              renderSideBySide: !mobileFlag,
              minimap: {
                enabled: !mobileFlag,
              },
            }}
          />
        ) : (
          <Hint>未选中文件</Hint>
        )}
      </div>
    </div>
  )
}

export const CommitEditor: React.FC<{}> = () => {
  return (
    <>
      <SiteHeader />
      <MainContent full noSpacing>
        <CommitEditorContent />
      </MainContent>
    </>
  )
}

export default CommitEditor
