import { cloneDeepWith } from 'lodash'
import qs from 'qs'

export const isDateString = (input: any) => {
  const reg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
  return typeof input === 'string' && reg.test(input)
}

// 如果有本地缓存被序列化，那么恢复的时候需要记得调用这个
// 将形如 2020-12-27T15:03:28.169Z 这样的字符串转化成 Date
export const processResponse = (input: unknown) => {
  // const id = Math.random()
  //   .toFixed(6)
  //   .substr(2)
  // const label = `${id} process took`
  // console.time(label)

  const doProcess = (value: unknown) => {
    if (input && typeof input === 'object') {
      return cloneDeepWith(value, (v) => {
        return isDateString(v) ? new Date(v) : undefined
      })
    }

    return input
  }

  const result = doProcess(input)
  // console.timeEnd(label)
  return result
}

type LinkInfo = {
  query: Record<string, string>
  rel: string
  url: string
}

export const parseGitHubHeaderLink = (
  link: string,
): {
  last: LinkInfo
  next: LinkInfo
  prev: LinkInfo
} => {
  return link
    .split(',')
    .map((part) => {
      const [_url, _rel] = part.split(';')
      const url = _url.trim().slice(1, -1)
      const query = qs.parse(url.split('?')[1])
      const rel = /rel="(.+)"/.exec(_rel)?.[1]

      return {
        url,
        query,
        rel,
      }
    })
    .reduce((obj, item) => {
      return {
        ...obj,
        [item.rel]: item,
      }
    }, {} as any)
}
