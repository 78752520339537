import React, { useEffect, useState, HTMLAttributes, useRef } from 'react'
import { SyncOutlined, WarningOutlined } from '@ant-design/icons'
import mediumZoom from 'medium-zoom'
import { getGitHubRawContent } from '../../services/github'

export const ImagePlaceholder: React.FC<HTMLAttributes<any>> = (props) => {
  const { children, style, ...rest } = props
  return (
    <span
      className="center"
      {...rest}
      style={{
        fontSize: 14,
        height: 100,
        border: '1px solid #ddd',
        padding: 20,
        marginBottom: 20,
        marginTop: 20,
        width: '100%',
        ...style,
      }}
    >
      {props.children}
    </span>
  )
}

export const Image: React.FC<{
  src?: string
  error?: Error
  loading?: boolean
  base64?: string
}> = (props) => {
  const { loading, error, src, base64 } = props
  const imgRef = useRef(null)

  useEffect(() => {
    if (imgRef.current) {
      const zoom = mediumZoom(imgRef.current)
      return () => {
        zoom.detach()
      }
    }
  }, [src, loading, error])

  if (loading) {
    return (
      <ImagePlaceholder>
        <span
          style={{
            display: 'inline-block',
          }}
        >
          <SyncOutlined
            spin
            style={{
              fontSize: 20,
              color: '#999',
            }}
          />
        </span>
      </ImagePlaceholder>
    )
  }

  if (error) {
    return (
      <ImagePlaceholder>
        <div
          style={{
            color: 'orange',
          }}
        >
          <WarningOutlined /> 图片加载失败: {error.message}
        </div>
      </ImagePlaceholder>
    )
  }

  return <img ref={imgRef} src={base64 ? `data:image/jpg;base64,${base64}` : src} alt="img" />
}

export const GitHubImage: React.FC<{
  owner: string
  repo: string
  relativePath: string
}> = (props) => {
  const { owner, repo, relativePath } = props
  const [isLoading, setIsLoading] = useState(true)
  const [content, setContent] = useState(null)
  const [error, setError] = useState<Error>(null)

  useEffect(() => {
    getGitHubRawContent(owner, repo, relativePath)
      .then((data) => {
        setContent(data.content)
      })
      .catch(setError)
      .finally(() => {
        setIsLoading(false)
      })
  }, [owner, repo, relativePath])

  return <Image base64={content} error={error} loading={isLoading} />
}
