import React, { Component } from 'react'
import { ErrorInfo } from 'lib-react-components'
import { UnauthorizedFallback } from './Fallback'
import { isAuthError } from '../../helpers/error'
import { Details } from '../_shared/Details'
import { css } from 'emotion'

const normalizeErrorMessage = (err: any) => {
  return typeof err === 'string' ? err : err?.message || 'Unknown error occurred'
}

export class ErrorBoundary extends Component<{
  fallback?: any
  details?: string
}> {
  state = { error: null }

  static getDerivedStateFromError(error) {
    return {
      error,
    }
  }

  render() {
    if (this.state.error) {
      console.error('caught in ErrorBoundary', { error: this.state.error })

      if (this.props.fallback) {
        return this.props.fallback
      }

      if (isAuthError(this.state.error)) {
        return <UnauthorizedFallback />
      }

      if (this.state.error) {
        return (
          <ErrorInfo
            error={{
              name: this.state?.error?.name || '错误',
              message: normalizeErrorMessage(this.state.error),
            }}
          >
            {this.props.details && (
              <Details summary="显示细节">
                <div
                  className={css`
                    font-size: 14px;
                  `}
                >
                  {this.props.details}
                </div>
              </Details>
            )}
          </ErrorInfo>
        )
      }
    }

    return this.props.children
  }
}
