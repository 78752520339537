import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { registerServiceWorker } from 'lib-core'
import { Root } from './Root'
import { LoadingScreen } from 'lib-react-components'
import { ENABLE_SERVICE_WORKER } from './config'
import { applyExternalCss, getEnhancedThemeConfig, loadThemeAssetsCache } from './services/theme'
import 'lib-react-components/esm/style.css'
import './assets/main.scss'

const isEnvDevelopment = process.env.NODE_ENV === 'development'

const render = async () => {
  loadThemeAssetsCache()
  applyExternalCss(getEnhancedThemeConfig().dark)

  ReactDOM.render(
    <Suspense fallback={<LoadingScreen text="准备中..." />}>
      <Root />
    </Suspense>,
    document.getElementById('root'),
  )
}

const main = async () => {
  console.log(`git-commit: ${process.env.REACT_APP_GIT_COMMIT}`)
  render()

  if (isEnvDevelopment && !ENABLE_SERVICE_WORKER) {
    // @ts-ignore
    module.hot.accept(render)
  }

  if (ENABLE_SERVICE_WORKER) {
    registerServiceWorker()
  }
}

main()
