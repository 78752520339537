import { SystemColorScheme } from 'lib-core'
import { createKvStorage } from 'lib-core'
import { Subject } from 'rxjs'

const _preference$ = new Subject<Preference>()

export const preference$ = _preference$.asObservable()

export type Preference = {
  editor: 'monaco' | 'slate'
  // TODO 移除
  lastUsed: {
    owner: string
    repo: string
  }
  colorScheme?: SystemColorScheme | ''
}

const defaultPreference: Preference = {
  editor: 'monaco',
  lastUsed: null,
  colorScheme: '',
}

const cache = createKvStorage<{
  value: Preference
}>('gitdocs_preference')

export const getPreference = () => {
  return {
    ...defaultPreference,
    ...cache.get('value'),
  }
}

export const updatePreference = (pref: Partial<Preference>) => {
  const current = getPreference()

  const next = {
    ...current,
    ...pref,
  }

  cache.set('value', next)
  _preference$.next(next)
}
