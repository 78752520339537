import React from 'react'
import { Button } from 'lib-react-components'
import { Tag } from './Tag'
import { css } from 'emotion'

export const Filter: React.FC<{
  filter: {
    tag?: string
  }
  onClear: (key?: string) => void
}> = (props) => {
  const {
    filter: { tag },
    onClear,
  } = props
  const hasAnyFilter = tag

  return (
    <>
      {hasAnyFilter && (
        <div
          className={css`
            margin-bottom: 20px;
          `}
        >
          {tag && (
            <div
              style={{
                marginRight: 10,
              }}
            >
              <Tag>{tag}</Tag>{' '}
              <Button
                linkLike
                onClick={() => {
                  onClear('tag')
                }}
              >
                &#10005; 清除
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  )
}
