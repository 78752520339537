import React from 'react'
import { DraftLegacy } from '../../types/types'
import { Button } from 'lib-react-components'
import { Modal } from 'antd'
import { css } from 'emotion'
import classNames from 'classnames'

/**
 * @deprecated
 */
export const DraftItemLegacy: React.FC<{
  draft: DraftLegacy
  onClick?: () => void
  active?: boolean
  onDelete: () => void
}> = (props) => {
  const { draft, onClick, active, onDelete } = props

  return (
    <div
      onClick={onClick}
      className={css`
        padding: 5px;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
          & .draft-item-btn {
            display: inline-block;
          }
        }
        background: ${active ? 'rgba(0, 0, 0, 0.2)' : 'none'};
      `}
    >
      <div
        style={{
          fontSize: 16,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {draft.title}
      </div>
      <div
        className="space-between"
        style={{
          fontSize: 14,
          color: '#666',
        }}
      >
        <div
          style={{
            maxWidth: `calc(100% - 30px)`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {draft.path}
        </div>
        <Button
          className={classNames(
            'draft-item-btn',
            css`
              display: none;
            `,
          )}
          linkLike
          size="mini"
          onClick={(e) => {
            e.stopPropagation()
            Modal.confirm({
              content: `确定删除 ${draft.title}?`,
              onOk: onDelete,
            })
          }}
        >
          删除
        </Button>
      </div>
    </div>
  )
}
