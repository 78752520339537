import { RouteNode } from './components/_core/Router'
import { NotFound } from './components/_core/Fallback'
import Home from './components/editor/Home'
import { Archive } from './components/remote/Archive'
import { ArticleViewer } from './components/remote/ArticleViewer'
import CommitEditor from './components/commit-editor/CommitEditor'
import DraftListPage from './components/working-tree/DraftListPage'
import DraftPage from './components/working-tree/DraftPage'
import DraftsLegacy from './components/legacy/DraftsLegacy'
import DraftEditor from './components/editor/DraftEditor'
import Fork from './components/legacy/Fork'
import IDE from './components/legacy/ide/IDE'
import LogsPage from './components/remote/LogsPage'
import Profile from './components/user/Profile'
import RecentRepos from './components/repo-manager/RecentRepos'
import { Repo } from './components/remote/Repo'
import RepoManager from './components/repo-manager/RepoManager'
import { lazy } from 'react'
import { repoTypes } from './components/github-repo-browser/data'

export const routes: RouteNode = {
  path: '/',
  children: [
    {
      path: '/',
      component: Home,
    },
    {
      path: '/repos',
      component: RepoManager,
    },
    {
      path: '/profile',
      component: Profile,
    },
    {
      path: ['/archive/:owner/:repo', '/archive/:owner/:repo/:ref+'],
      component: Archive,
    },
    {
      path: '/recent',
      component: RecentRepos,
    },
    {
      path: '/commit/:owner/:repo',
      component: CommitEditor,
    },
    {
      path: [
        '/remote/:owner/:repo/tree/:ref+/search',
        '/remote/:owner/:repo/search',
        '/remote/:owner/:repo/tree/:ref+',
        '/remote/:owner/:repo',
      ],
      component: Repo,
    },
    {
      path: '/remote/:owner/:repo/blob/:blobRef+',
      component: ArticleViewer,
    },
    {
      path: ['/drafts/edit/:owner/:repo/new', '/drafts/edit/:owner/:repo/:_id'],
      component: DraftEditor,
    },
    {
      path: '/drafts/:owner/:repo',
      component: DraftListPage,
    },
    {
      path: '/drafts/:owner/:repo/:path+',
      component: DraftPage,
    },
    {
      path: '/:owner/:repo/logs/:sha?',
      component: LogsPage,
    },
    {
      path: '/ide/:owner/:repo',
      component: IDE,
    },
    {
      path: '/drafts-legacy/:owner/:repo',
      component: DraftsLegacy,
    },
    {
      path: '/fork/:owner/:repo/:blobSha',
      component: Fork,
    },
    {
      path: ['/github', ...repoTypes.map((t) => '/github/' + t)],
      component: lazy(() => import('./components/github-repo-browser/GitHubRepoBrowser')),
    },
    {
      component: NotFound,
    },
  ],
}
