export const DEFAULT_SORT = 'updated-desc'
export const CONTENT_WIDTH = 800
export const BAR_HEIGHT = 60
export const STATUS_BAR_HEIGHT = 30
export const ENABLE_SERVICE_WORKER = process.env.NODE_ENV === 'production'
export const ENABLE_SERVICE_CACHE = process.env.NODE_ENV === 'production' || true

export const SORT_OPTIONS = [
  {
    label: '最新',
    value: 'date-desc',
  },
  {
    label: '最旧',
    value: 'date-asc',
  },
  {
    label: '最近更新',
    value: 'updated-desc',
  },
  {
    label: '标题 A-Z',
    value: 'title-asc',
  },
  {
    label: '标题 Z-A',
    value: 'title-desc',
  },
]
