import { createSubscriptionContext, createSubscriptionHooks } from '../hooks/useSubscribe'

// TODO 使用 rxjs
const appEvents = createSubscriptionContext<
  | {
      key: 'branchChange'
      value: any
    }
  | {
      key: 'pull'
      value: any
    }
>()

export const {
  useNotify: useNotifyAppEvents,
  useSubscribe: useSubscribeAppEvents,
} = createSubscriptionHooks(appEvents)
