import React from 'react'
import { Route, RouteProps, Switch, Router as _Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export type RouteConfig = RouteProps

export type RouteNode = {
  name?: string
  children?: RouteNode[]
} & RouteConfig

const renderRouteNode = (node: RouteNode, idx: number, isRoot: boolean = true) => {
  const { name, children, ...props } = node
  const key = props.path ? (Array.isArray(props.path) ? props.path.join('|') : props.path) : idx

  if (children) {
    return (
      <Route {...props}>
        <Switch>{children.map((childNode, i) => renderRouteNode(childNode, i, false))}</Switch>
      </Route>
    )
  }

  const ele = <Route key={key} exact {...props} />

  if (isRoot) {
    return <Switch>{ele}</Switch>
  }
  return ele
}

export const Router: React.FC<{
  routes: RouteNode
}> = (props) => {
  const children = renderRouteNode(props.routes, 0)

  // eslint-disable-next-line react/jsx-pascal-case
  return <_Router history={history}>{children}</_Router>
}
