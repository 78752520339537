import React, { memo, createContext, useContext, HTMLAttributes } from 'react'
import { Markdown } from 'lib-react-components'
import { GitHubImage, Image } from './GitHubImage'
import { extractHeadings } from '../../helpers/markdown'
import { Toc } from './Toc'
import './Article.scoped.scss'
import { Service } from '../../types/service'
import { PostMeta, PostTags } from './Post'
import { useURLHelper } from '../../hooks/useURLHelper'
import qs from 'qs'
import path from 'path'
import { useRepoParams } from '../../hooks/useRepoQuery'
import { Details } from './Details'
import { css } from 'emotion'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useNodeId } from 'lib-react-hooks'

type ArticleData = Service.Article

const MDCtx = createContext<{ data?: ArticleData }>({})

const MDImage: React.FC<{ node: any }> = (props) => {
  const { data } = useContext(MDCtx)
  const { repo, owner } = useRepoParams()
  const {
    node: { url },
  } = props
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return <Image src={url} />
  }

  return <GitHubImage owner={owner} repo={repo} relativePath={path.resolve(data.path, '..', url)} />
}

export const Article: React.FC<
  {
    article: ArticleData
  } & HTMLAttributes<HTMLDivElement>
> = memo(({ article, style, ...rest }) => {
  const headings = extractHeadings(article.content)
  const nodeId = useNodeId().toString()
  const url = useURLHelper()
  const mobileFlag = useIsMobile()

  return (
    <div
      {...rest}
      style={{
        border: 'none',
        marginBottom: 50,
        paddingTop: 20,
        ...style,
      }}
    >
      <div
        className="inner"
        style={{
          flexDirection: 'column',
        }}
      >
        <div className="content">
          <div className="body">
            <div className="toc-wrap">
              {!!headings.length && (
                <Details
                  summary="目录"
                  defaultOpen={!mobileFlag}
                  className={css`
                    margin-bottom: 5px;
                  `}
                >
                  <Toc
                    className={css`
                      margin-left: 20px;
                    `}
                    headings={headings}
                    getContentEl={() => {
                      return document.getElementById(nodeId) as any
                    }}
                  />
                </Details>
              )}
            </div>
            <MDCtx.Provider
              value={{
                data: article,
              }}
            >
              <Markdown
                id={nodeId}
                markdown={article.content}
                customRenderers={{
                  image: MDImage,
                }}
              />
            </MDCtx.Provider>
          </div>
          <div
            style={{
              marginTop: 50,
            }}
          >
            <PostMeta date={article.date} updated={article.updated} />
            <PostTags
              tags={article?.tags?.map((tag) => {
                return {
                  tag,
                  url:
                    url.getIndexUrl() +
                    `?${qs.stringify({
                      tag,
                    })}`,
                }
              })}
            />
          </div>
        </div>
      </div>
    </div>
  )
})
