import { SWRInfiniteResponseInterface } from 'swr-fork'
import { DataList } from '../types/common'
import { useRef, useCallback } from 'react'
import { omit, last } from 'lodash'

export const useEnhancedSWRPages = <T extends {}, K>(
  swr: SWRInfiniteResponseInterface<DataList<T>, K>,
): EnhancedSWR<T, K> => {
  const { data, setSize, isValidating, ...rest } = swr
  const isFirstLoadMoreRef = useRef(true)

  const pagination = omit(last(data), 'items')
  const isReachingEnd = !isValidating && pagination?.next === null
  const isEmpty =
    !isValidating &&
    ((pagination?.total !== undefined && pagination.total === 0) || last(data)?.items?.length === 0)

  const loadMore = useCallback(() => {
    // isValidating 时 return，第一次 loadMore 的时候初始加载可能还没完成
    // 但如果不加限制很可能出其他问题，load more 一次应该触发一个
    // 这里用 ref 做了判断，大部分情况应该没问题，但不排除如果第一次 load more 之后初始的 loading 还没结束的情况
    // 这个时候第二次 load more 还是会有问题
    if ((isValidating && !isFirstLoadMoreRef.current) || isReachingEnd) {
      return
    }
    isFirstLoadMoreRef.current = false
    setSize((n) => n + 1)
  }, [isValidating, setSize, isReachingEnd])

  const isLoadingInitial = isValidating && Object.keys(pagination || {}).length === 0
  const isLoadingMore = isValidating && !isLoadingInitial

  return {
    ...rest,
    pages: data?.map((d) => d.items) || [],
    pagination,
    isReachingEnd,
    loadMore,
    isValidating,
    isEmpty,
    isLoadingInitial,
    isLoadingMore,
  }
}

export interface EnhancedSWR<T extends {}, K>
  extends Omit<SWRInfiniteResponseInterface<DataList<T>, K>, 'setSize'> {
  pages: T[][]
  pagination: Pick<DataList<T>, 'page' | 'limit' | 'next' | 'total'>
  isReachingEnd: boolean
  loadMore: () => void
  isValidating: boolean
  isEmpty: boolean
  isLoadingInitial: boolean
  isLoadingMore: boolean
}
