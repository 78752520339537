import React from 'react'
import { useParams } from 'react-router-dom'
import { CommitLogs } from './CommitLogs'
import { MainContent } from '../_shared/MainContent'
import { SiteHeader } from '../_core/SiteHeader'
import { useGitHubCommits } from '../../services/github'

export const LogsPage: React.FC<{}> = (props) => {
  const { sha, owner, repo } = useParams<{
    sha: string
    owner: string
    repo: string
  }>()
  const { data: commits } = useGitHubCommits(owner, repo, sha)

  return (
    <>
      <SiteHeader />
      <MainContent>
        <CommitLogs commits={commits} />
      </MainContent>
    </>
  )
}

export default LogsPage
