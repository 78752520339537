import React, { useState } from 'react'
import { Input, Tabs } from 'antd'
import { Button } from 'lib-react-components'
import { cloneRepo } from '../../services/git'
import { GitHubRepoList } from './GitHubRepoList'
import { GitHub } from '../../types/github'

const parseGithubUrl = (url: string) => {
  try {
    const [owner, repo] = url.replace('https://github.com/', '').split('/')
    if (!owner || !repo) {
      return null
    }
    return { owner, repo }
  } catch (error) {
    return null
  }
}

export const CloneRepoForm: React.FC<{
  onSuccess?: (repo: GitHub.Repo) => void
  onFailure?: (err: any) => void
}> = (props) => {
  const { onSuccess, onFailure } = props
  const [githubUrl, setGithubUrl] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [tab, setTab] = useState('browse')
  const [activeRepo, setActiveRepo] = useState(null)

  const handleClone = async (url: string) => {
    if (error) {
      return
    }

    const { owner, repo } = parseGithubUrl(url)

    setIsLoading(true)
    return cloneRepo(owner, repo)
      .then(onSuccess)
      .catch(onFailure)
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div>
      <Tabs
        activeKey={tab}
        onChange={(e) => {
          setTab(e)
        }}
      >
        <Tabs.TabPane key="url" tab="From URL">
          <Input
            placeholder="GitHub URL"
            value={githubUrl}
            onChange={(e) => {
              const url = e.target.value
              setGithubUrl(url)
              if (url) {
                const result = parseGithubUrl(url)
                if (!result) {
                  setError('Invalid url')
                } else if (error) {
                  setError('')
                }
              } else {
                setError('')
              }
            }}
            suffix={
              <Button
                isLoading={isLoading}
                disabled={!!error || !githubUrl}
                onClick={() => {
                  handleClone(githubUrl)
                }}
              >
                Clone
              </Button>
            }
          />
          {error && (
            <div
              style={{
                color: 'red',
              }}
            >
              {error}
            </div>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane key="browse" tab="Browse">
          <GitHubRepoList
            renderRepoAction={(repo) => {
              const isActive = activeRepo === repo.id

              return (
                <Button
                  size="small"
                  isLoading={isActive && isLoading}
                  onClick={() => {
                    setActiveRepo(repo.id)
                    handleClone(repo.html_url).finally(() => {
                      setActiveRepo(null)
                    })
                  }}
                >
                  Clone
                </Button>
              )
            }}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
