import { omit } from 'lodash'
import React from 'react'
import { useQueryState } from '../../hooks/useQueryState'
import { Filter } from '../_shared/Filter'

export const RouterFilter: React.FC<{}> = (props) => {
  const [query, setQuery] = useQueryState<{
    tag: string
  }>()

  return (
    <Filter
      filter={{
        tag: query.tag,
      }}
      onClear={(key) => {
        setQuery((query) => {
          if (key) {
            return omit(query, [key]) as any
          }
          return {}
        })
      }}
    />
  )
}
