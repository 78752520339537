import React, { FC, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { MDNode } from 'lib-core/esm/markdown/types'
import './Toc.scoped.scss'
import { cx } from 'emotion'

export const Toc: FC<{
  headings: {
    depth: number
    value: string
    node: MDNode
  }[]
  getContentEl?: () => HTMLDivElement
  className?: string
}> = (props) => {
  const { headings, getContentEl, className } = props
  const [activeTocIndex, setActiveTocIndex] = useState(-1)

  const handleScroll = useCallback(() => {
    const contentEl = getContentEl?.()
    if (!contentEl) {
      return
    }
    const headingPadding = 5
    const scrollTop = document.scrollingElement.scrollTop
    const positions = Array.from(contentEl.querySelectorAll('h1, h2, h3, h4, h5, h6')).map(
      (node) => {
        return node.getBoundingClientRect().top + scrollTop - headingPadding
      },
    )

    let active: number
    for (let i = 0; i < positions.length; i++) {
      if (positions[i] < scrollTop) {
        if (!positions[i + 1] || positions[i + 1] > scrollTop) {
          active = i
          break
        }
      }
    }
    if (activeTocIndex !== active) {
      setActiveTocIndex(active)
    }
  }, [activeTocIndex, getContentEl])

  const handleTOCItemClick = (idx) => {
    return () => {
      const contentEl = getContentEl?.()
      if (!contentEl) {
        return
      }
      document.scrollingElement.scrollTop =
        contentEl.querySelectorAll('h1, h2, h3, h4, h5, h6')[idx].getBoundingClientRect().top +
        document.scrollingElement.scrollTop
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  if (headings.length === 0) {
    return null
  }

  return (
    <div className={cx('toc', className)}>
      <div className="content">
        {headings.map((item, idx) => {
          const active = activeTocIndex === idx

          return (
            <div
              className={classNames(['item', active && 'item--active'])}
              key={idx}
              style={{
                marginLeft: Math.max(0, item.depth - 2) * 10,
                fontWeight: item.depth <= 2 ? 'bold' : 'normal',
                cursor: getContentEl ? 'pointer' : undefined,
              }}
              onClick={handleTOCItemClick(idx)}
            >
              {item.value}
            </div>
          )
        })}
      </div>
    </div>
  )
}
