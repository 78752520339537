import React from 'react'

export type PanelConfig = {
  label: string
  key: string
  icon: any
}

export const VerticalEditorPanels: React.FC<{
  panels: PanelConfig[]
  onChange: (e: string) => void
  value: string
}> = (props) => {
  const { panels, value, onChange } = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 50,
        height: '100%',
        background: '#222',
        userSelect: 'none',
        flex: '0 0 auto',
      }}
    >
      {panels.map((panel) => {
        const active = panel.key === value

        return (
          <div
            title={panel.label}
            key={panel.key}
            onClick={() => {
              onChange(panel.key)
            }}
            style={{
              color: active ? '#fff' : '#666',
              height: 50,
              width: '100%',
              cursor: 'pointer',
            }}
            className="center"
          >
            {panel.icon || panel.label}
          </div>
        )
      })}
    </div>
  )
}
