import React from 'react'
import { EnhancedSWR } from '../../hooks/useEnhancedSWRPages'
import { NoResult } from './NoResult'
import { ErrorInfo, Loading, LoadingScreen } from 'lib-react-components'
import { identity } from 'lodash'
import { css } from 'emotion'

export const List = <
  T extends {},
  K,
  P extends {
    data: T
  }
>(props: {
  enhancedSWR: EnhancedSWR<T, K>
  itemComponent?: React.FC<P>
  preprocessItemProps?: (data: T) => P
  renderItem?: (ctx: { data: T }) => any
  showLoadingMore?: boolean
}) => {
  const {
    enhancedSWR: { pages, isEmpty, isReachingEnd, error, isLoadingInitial, isLoadingMore },
    itemComponent: ItemComponent,
    preprocessItemProps,
    renderItem,
    showLoadingMore,
  } = props

  if (error) {
    return <ErrorInfo error={error} />
  }

  if (isLoadingInitial) {
    return <LoadingScreen text="加载列表数据..." />
  }

  if (isEmpty) {
    return <NoResult title="暂无数据" />
  }

  return (
    <div
      className={css`
        margin-bottom: 20px;
      `}
    >
      {pages.map((items) => {
        return items.map((item, idx) => {
          if (renderItem) {
            return renderItem({ data: item })
          }
          const props = preprocessItemProps(item)
          return <ItemComponent key={idx} {...props} />
        })
      })}
      {!isReachingEnd && showLoadingMore && (
        <Loading
          center
          text="加载更多..."
          style={{
            margin: '40px 0',
            visibility: isLoadingMore ? 'visible' : 'hidden',
          }}
        />
      )}
    </div>
  )
}

List.defaultProps = {
  preprocessItemProps: identity,
  showLoadingMore: true,
}
